<template>
    <div>
        <div class="table-container">
            <table
                class="table b-table table-bordered b-table-no-border-collapse sticky-header"
            >
                <thead class="thead-light" style="position: sticky; top: 40px">
                    <tr>
                        <th v-for="header of tableFields" :key="header.key">
                            <template v-if="header.key === 'num'">
                                <b-button @click="openAll()">
                                    <i
                                        class="icon icon-chevron-circle icon-rotate-180"
                                        v-if="open"
                                    ></i>
                                    <i
                                        class="icon icon-chevron-circle"
                                        v-if="!open"
                                    ></i>
                                </b-button>
                            </template>
                            <template v-else>
                                {{ header.label }}
                            </template>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td
                            v-for="(header, index) of tableFields"
                            :key="'header-' + header.key"
                            class="td-numbering"
                        >
                            <div class="text-center w-100px" v-if="index !== 0 && header.key !== 'action'">
                                {{ index }}
                            </div>
                        </td>
                    </tr>
                    <tr
                        v-for="(item, index) of unReport"
                        :key="`${index}_${item.name}`"
                    >
                        <template v-if="item.visible">
                            <td
                                v-for="header of tableFields"
                                :key="'body-' + header.key"
                                style="
                                    padding: 0.75rem;
                                    font-size: 0.7777777778rem;
                                    font-weight: 500;
                                    line-height: 1.3;
                                    color: #0d1a2e !important;
                                    text-transform: none;
                                "
                                :style="
                                    item.region.endsWith('00')
                                        ? 'background-color: rgba(176, 224, 255, .5)!important;'
                                        : item.dataType === 2
                                        ? 'background-color: #d6d8db !important;'
                                        : ''
                                "
                            >
                                <template v-if="header.key === 'num'">
                                    <b-button
                                        v-if="item.region.endsWith('00')"
                                        @click="openChilds(item)"
                                        v-model="item.open"
                                    >
                                        <i
                                            class="icon icon-chevron-circle icon-rotate-180"
                                            v-if="item.open"
                                        ></i>
                                        <i
                                            class="icon icon-chevron-circle"
                                            v-if="!item.open"
                                        ></i>
                                    </b-button>
                                </template>
                                <template v-if="header.key === 'name'">
                                    <div v-if="item.region.slice(2, 6) !== '0101'" class="text-left w-200px">
                                        {{ item.region }} - {{ item.name }}
                                    </div>
                                    <div v-else class="text-left w-200px">
                                        {{ item.name }}
                                    </div>
                                </template>
                                <template v-else-if="header.key === 'incomes'">
                                    <div>
                                        {{ $n(item.incomes) }}
                                    </div>
                                </template>
                                <template v-else-if="header.key === 'taxes'">
                                    <div>
                                        {{ $n(item.taxes) }}
                                    </div>
                                </template>
                                <template
                                    v-else-if="header.key === 'not_taxes'"
                                >
                                    <div>
                                        {{ $n(item.not_taxes) }}
                                    </div>
                                </template>
                                <template v-else-if="header.key === 'transfer'">
                                    <div>
                                        {{ $n(item.transfer) }}
                                    </div>
                                </template>
                                <template v-else-if="header.key === '101202'">
                                    <div>
                                        {{
                                            item["101202"]
                                                ? $n(item["101202"])
                                                : 0
                                        }}
                                    </div>
                                </template>
                                <template v-else-if="header.key === '104102'">
                                    <div>
                                        {{
                                            item["104102"]
                                                ? $n(item["104102"])
                                                : 0
                                        }}
                                    </div>
                                </template>
                                <template v-else-if="header.key === '104302'">
                                    <div>
                                        {{
                                            item["104302"]
                                                ? $n(item["104302"])
                                                : 0
                                        }}
                                    </div>
                                </template>
                                <template v-else-if="header.key === '104401'">
                                    <div>
                                        {{
                                            item["104401"]
                                                ? $n(item["104401"])
                                                : 0
                                        }}
                                    </div>
                                </template>
                                <template v-else-if="header.key === '105315'">
                                    <div>
                                        {{
                                            item["105315"]
                                                ? $n(item["105315"])
                                                : 0
                                        }}
                                    </div>
                                </template>
                                <template v-else-if="header.key === '104501'">
                                    <div>
                                        {{
                                            item["104501"]
                                                ? $n(item["104501"])
                                                : 0
                                        }}
                                    </div>
                                </template>
                                <template v-else-if="header.key === '105430'">
                                    <div>
                                        {{
                                            item["105430"]
                                                ? $n(item["105430"])
                                                : 0
                                        }}
                                    </div>
                                </template>
                                <template v-else-if="header.key === '201510'">
                                    <div>
                                        {{
                                            item["201510"]
                                                ? $n(item["201510"])
                                                : 0
                                        }}
                                    </div>
                                </template>
                                <template v-else-if="header.key === '206115'">
                                    <div>
                                        {{
                                            item["206115"]
                                                ? $n(item["206115"])
                                                : 0
                                        }}
                                    </div>
                                </template>
                                <template v-else-if="header.key === '204170'">
                                    <div>
                                        {{
                                            item["204170"]
                                                ? $n(item["204170"])
                                                : 0
                                        }}
                                    </div>
                                </template>
                                <template v-else-if="header.key === '206109'">
                                    <div>
                                        {{
                                            item["206109"]
                                                ? $n(item["206109"])
                                                : 0
                                        }}
                                    </div>
                                </template>
                                <template
                                    v-else-if="header.key === 'main_capital'"
                                >
                                    <div>
                                        {{ $n(item.main_capital) }}
                                    </div>
                                </template>
                                <template v-else-if="header.key === '301102'">
                                    <div>
                                        {{
                                            item["301102"]
                                                ? $n(item["301102"])
                                                : 0
                                        }}
                                    </div>
                                </template>
                                <template v-else-if="header.key === '303101'">
                                    <div>
                                        {{
                                            item["303101"]
                                                ? $n(item["303101"])
                                                : 0
                                        }}
                                    </div>
                                </template>
                                <template v-else-if="header.key === '303202'">
                                    <div>
                                        {{
                                            item["303202"]
                                                ? $n(item["303202"])
                                                : 0
                                        }}
                                    </div>
                                </template>
                                <template v-else-if="header.key === '402303'">
                                    <div>
                                        {{
                                            item["402303"]
                                                ? $n(item["402303"])
                                                : 0
                                        }}
                                    </div>
                                </template>
                                <template v-else-if="header.key === '402301'">
                                    <div
                                        class="w-150px"
                                        v-if="
                                            params.reg && !params.reg.includes('0101') && item.region.slice(4, 6) !== '00' && item.region.slice(4, 6) !== '01'
                                        "
                                    >
                                        <b-form-input
                                            v-model.number="item['402301']"
                                            :type="'number'"
                                            @input="updateData(item)"
                                            min="0"
                                        ></b-form-input>
                                    </div>
                                    <div v-else>
                                        <div>
                                            {{
                                                item["402301"]
                                                    ? $n(item["402301"])
                                                    : 0
                                            }}
                                        </div>
                                    </div>
                                </template>
                                <template v-else-if="header.key === '402302'">
                                    <div>
                                        {{
                                            item["402302"]
                                                ? $n(item["402302"])
                                                : 0
                                        }}
                                    </div>
                                </template>
                                <template v-else-if="header.key === 'action'">
                                    <div
                                        v-if="
                                            !params.reg.includes('0101') &&
                                            item.region.slice(4, 6) === '00'
                                        "
                                    >
                                        <b-dropdown
                                            dropleft
                                            class="more-dropdown"
                                        >
                                            <template v-slot:button-content>
                                                <i class="icon icon-more"></i>
                                            </template>
                                            <template>
                                                <b-dropdown-item
                                                    @click="saveData(item)"
                                                >
                                                    Сохранить
                                                </b-dropdown-item>
                                                <b-dropdown-item
                                                    @click="loadData()"
                                                >
                                                    Обновить
                                                </b-dropdown-item>
                                            </template>
                                        </b-dropdown>
                                    </div>
                                </template>
                            </td>
                        </template>
                    </tr>
                </tbody>
            </table>
        </div>
        <div>
            <loading
                :active="loading"
                is-full-screen
                spinner="bar-fade-scale"
                color="#6495ED"
            />
        </div>
    </div>
</template>
<script>
import { Ax } from '@/utils';
import moment from 'moment';
import VueElementLoading from "vue-element-loading";
import { type } from '@amcharts/amcharts4/core';

export default {
    name: "application-form-budget-msu-income",
    components: { 'loading': VueElementLoading },
    props: {
        params: {}
    },
    data() {
        return {
            sheet: null,
            obl: null,
            access_level: 1,
            listReg: [],
            curParams: {},
            reg: null,
            reg_name: null,
            qvartal: null,
            year: null,
            curDate: null,
            dateTo: null,
            repCode: 'budget-msu',
            repName: 'Сводная информация по бюджетам МСУ',
            loading: false,
            tableFields: [
                {
                    key: 'num',
                    label: '№'
                },
                {
                    key: 'name',
                    label: 'Райгород'
                },
                {
                    key: 'incomes',
                    label: 'Доходы'
                },
                {
                    key: 'taxes',
                    label: 'Налоговые'
                },
                {
                    key: '101202',
                    label: 'ИПН с доходов, не облагаемых у источников выплаты'
                },
                {
                    key: '104102',
                    label: 'Налог на имущество'
                },
                {
                    key: '104302',
                    label: 'Земельный налог'
                },
                {
                    key: '104401',
                    label: 'Налог на транспортные средства'
                },
                {
                    key: '105315',
                    label: 'Плата за пользование земельными участками'
                },
                {
                    key: '104501',
                    label: 'Единый земельный налог'
                },
                {
                    key: '105430',
                    label: 'плата за размещение наружной (визуальной) рекламы'
                },
                {
                    key: 'not_taxes',
                    label: 'Неналоговые'
                },
                {
                    key: '201510',
                    label: 'доходы от имущественного найма (аренды) государственного имущества'
                },
                {
                    key: '206115',
                    label: 'добровольные сборы физических и юридических лиц'
                },
                {
                    key: '204170',
                    label: 'штрафы, взимаемые акимами за адм.прав-ия, предусм-е КоАП РК'
                },
                {
                    key: '206109',
                    label: 'прочие неналоговые поступления'
                },
                {
                    key: 'main_capital',
                    label: 'Поступления от продажи основного капитала'
                },
                {
                    key: '303101',
                    label: 'поступления от продажи земельных участков'
                },
                {
                    key: '303202',
                    label: 'плата за продажу права аренды земельных участков'
                },
                {
                    key: 'transfer',
                    label: 'Трансферты'
                },
                {
                    key: '402303',
                    label: 'трансферты общего характера'
                },
                {
                    key: '402301',
                    label: 'целевые текущие трансферты'
                },
                {
                    key: '402302',
                    label: 'целевые трансферты на развитие'
                }
            ],
            unReport: [],
            curTab: 0,
            open: true
        };
    },

    async mounted() {
        await this.getAccessLevel();
        this.$watch('params', () => {
            this.tableFields = [
                {
                    key: 'num',
                    label: '№'
                },
                {
                    key: 'name',
                    label: 'Райгород'
                },
                {
                    key: 'incomes',
                    label: 'Доходы'
                },
                {
                    key: 'taxes',
                    label: 'Налоговые'
                },
                {
                    key: '101202',
                    label: 'ИПН с доходов, не облагаемых у источников выплаты'
                },
                {
                    key: '104102',
                    label: 'Налог на имущество'
                },
                {
                    key: '104302',
                    label: 'Земельный налог'
                },
                {
                    key: '104401',
                    label: 'Налог на транспортные средства'
                },
                {
                    key: '105315',
                    label: 'Плата за пользование земельными участками'
                },
                {
                    key: '104501',
                    label: 'Единый земельный налог'
                },
                {
                    key: '105430',
                    label: 'плата за размещение наружной (визуальной) рекламы'
                },
                {
                    key: 'not_taxes',
                    label: 'Неналоговые'
                },
                {
                    key: '201510',
                    label: 'доходы от имущественного найма (аренды) государственного имущества'
                },
                {
                    key: '206115',
                    label: 'добровольные сборы физических и юридических лиц'
                },
                {
                    key: '204170',
                    label: 'штрафы, взимаемые акимами за адм.прав-ия, предусм-е КоАП РК'
                },
                {
                    key: '206109',
                    label: 'прочие неналоговые поступления'
                },
                {
                    key: 'main_capital',
                    label: 'Поступления от продажи основного капитала'
                },
                {
                    key: '301102',
                    label: 'от продажи имущества'
                },
                {
                    key: '303101',
                    label: 'поступления от продажи земельных участков'
                },
                {
                    key: '303202',
                    label: 'плата за продажу права аренды земельных участков'
                },
                {
                    key: 'transfer',
                    label: 'Трансферты'
                },
                {
                    key: '402303',
                    label: 'трансферты общего характера'
                },
                {
                    key: '402301',
                    label: 'целевые текущие трансферты'
                },
                {
                    key: '402302',
                    label: 'целевые трансферты на развитие'
                }
            ]
            this.reportOpen(false);
        })
    },
    created() {
        this.$on('budgetMsu', (reg, reg_name, period, date_to, qvartal, year, obl, uuid, sheet) => {
            console.log(reg)
            this.sheet = sheet;
            this.reportOpen(false);
            this.reg = reg;
            this.year = year;
            this.qvartal = qvartal;
            this.reg_name = reg_name;
            this.dateTo = moment(date_to).format('YYYY-MM-DD');
            this.sheet = sheet;
        });
    },
    methods: {
        async getAccessLevel() {
            try {
                const response = await fetch(`/api-py/user-modules/${this.$store.getters.user_uuid}/005.003.006`);

                const modules_access = await response.json();
                if (modules_access.length > 1) {
                    this.makeToast("danger", "Ошибка", "У пользователя несколько записей")
                }
                if (modules_access.length === 0) {
                    this.access_level = 1;
                } else {
                    for (const ma of modules_access) {
                        this.access_level = ma.access_level;
                    }
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса load_user_access_level', error.toString());
            }
        },
        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        },
        async reportOpen(upd) {
            this.loading = true;
            this.unReport = [];
            this.curParams = {
                region: this.params.reg,
                period: this.params.period,
                date_to: moment(this.params.date_to).format('YYYY-MM-DD'),
                qvartal: this.params.qvartal,
                year: this.params.year,
                report: false,
                form: this.repCode,
                obl: this.params.obl,
                uuid: this.params.uuid,
                sheet: this.params.sheet.code,
                update: upd
            };
            if (!upd && this.params.reg.slice(4, 6) === '00') {
                this.tableFields.push({
                    key: 'action',
                    label: ''
                })
            }
            Ax(
                {
                    url: `/api-py/get-fulldata-budget-msu/` + JSON.stringify(this.curParams),
                    method: 'GET'
                },
                (data) => {
                    if (data.unReport === 0) {
                        this.makeToast('info', 'Внимание', 'Данных по таким параметрам нет.');
                    } else {
                        const total = {};
                        for (const d of data.unReport.sort((a, b) => a.sort_name > b.sort_name)) {
                            const i = {
                                name: d.name,
                                region: d.code
                            }
                            const region_list = []
                            for (const child of d.childs.sort((a, b) => a.sort_name > b.sort_name)) {
                                const attr = child.code;
                                if (i[attr]) {
                                    i[attr] += child.value;
                                } else {
                                    i[attr] = child.value;
                                }
                                const c = {
                                    region: child.region,
                                    name: child.name
                                }
                                if (!region_list.some(element => element.region === c.region)) {
                                    region_list.push(c);
                                }
                            }
                            const transport_keys = ['104401', '104402']
                            let transport = 0;
                            for (const key of transport_keys) {
                                if (i[key]) {
                                    transport += i[key]
                                }
                            }
                            i['104401'] = transport;
                            const transfer_keys = ['402303', '402301', '402302'];
                            let transfer = 0;
                            for (const key of transfer_keys) {
                                if (i[key]) {
                                    transfer += i[key];
                                }
                            }
                            i.transfer = transfer;
                            const not_taxes_key = ['201510', '206115', '204170', '206109'];
                            let not_taxes = 0;
                            for (const key of not_taxes_key) {
                                if (i[key]) {
                                    not_taxes += i[key];
                                }
                            }
                            i.not_taxes = not_taxes;
                            const taxes_key = ['101202', '104102', '104302', '104401', '105315', '104501', '105430'];
                            let taxes = 0;
                            for (const key of taxes_key) {
                                if (i[key]) {
                                    taxes += i[key];
                                }
                            }
                            i.taxes = taxes;
                            const main_capital_key = ['303101', '303202', '301102'];
                            let main_capital = 0;
                            for (const key of main_capital_key) {
                                if (i[key]) {
                                    main_capital += i[key];
                                }
                            }
                            i.main_capital = main_capital;
                            const income = taxes + not_taxes + transfer + main_capital;
                            i.incomes = income;
                            i.visible = true;
                            i.open = true;
                            this.unReport.push(i)
                            for (const reg of region_list) {
                                const child_reg = d.childs.filter((a) => a.region === reg.region);
                                if (child_reg.length > 0) {
                                    for (const item of child_reg) {
                                        const attr = item.code;
                                        if (reg[attr]) {
                                            reg[attr] += item.value;
                                        } else {
                                            reg[attr] = item.value;
                                        }
                                        if (item.id) {
                                            reg.id = item.id;
                                            if (upd) {
                                                reg.changed = true;
                                            } else {
                                                reg.changed = false;
                                            }
                                        }
                                    }
                                }
                                let transport = 0;
                                for (const key of transport_keys) {
                                    if (reg[key]) {
                                        transport += reg[key]
                                    }
                                }
                                reg['104401'] = transport;
                                let transfer = 0
                                for (const key of transfer_keys) {
                                    if (reg[key]) {
                                        transfer += reg[key];
                                    }
                                }
                                reg.transfer = transfer;
                                let not_taxes = 0;
                                for (const key of not_taxes_key) {
                                    if (reg[key]) {
                                        not_taxes += reg[key];
                                    }
                                }
                                reg.not_taxes = not_taxes;
                                let taxes = 0;
                                for (const key of taxes_key) {
                                    if (reg[key]) {
                                        taxes += reg[key];
                                    }
                                }
                                reg.taxes = taxes;
                                let main_capital = 0;
                                for (const key of main_capital_key) {
                                    if (reg[key]) {
                                        main_capital += reg[key];
                                    }
                                }
                                reg.main_capital = main_capital;
                                const income = taxes + not_taxes + transfer + main_capital;
                                reg.incomes = income;
                                reg.visible = true;
                                this.unReport.push(reg);
                            }
                        }
                        for (const key of this.tableFields) {
                            if (key.key !== 'num') {
                                if (key.key === 'name') {
                                    total.name = 'Всего';
                                } else {
                                    total[key.key] = 0;
                                }
                            }
                        }
                        for (const regs of this.unReport.filter(x => x.region.slice(4, 6) === '00')) {
                            for (const key of Object.keys(regs)) {
                                if (typeof regs[key] === 'number') {
                                    if (total[key]) {
                                        total[key] += regs[key]
                                    } else {
                                        total[key] = regs[key]
                                    }
                                }
                            }
                        }
                        total.region = this.params.obl + '0101'
                        total.visible = true;
                        this.unReport.unshift(total)
                    }
                    this.curTab = 0;
                    this.loading = false;
                },
                (error) => {
                    this.makeToast('danger', 'Ошибка запроса', error.toString());
                    this.loading = false;
                }
            )
        },
        openAll() {
            this.open = !this.open;
            for (const r of this.unReport.filter(x => x.region.slice(4, 6) != '00' && x.region.slice(2, 6) != '0101')) {
                r.visible = this.open;
            }
            for (const r of this.unReport.filter(x => x.region.slice(4, 6) == '00')) {
                r.open = this.open;
            }
        },
        openChilds(item) {
            item.open = !item.open;
            for (const r of this.unReport.filter(x => x.region !== item.region && x.region.slice(0, 4) === item.region.slice(0, 4))) {
                r.visible = item.open;
            }
        },
        rowClass(item, type) {
            if (!item || type !== 'row') {
                return;
            }
            if (!item.visible) {
                return 'is-hidden';
            }
        },
        updateData(item) {
            item.changed = true;
            const transfer_keys = ['402303', '402301', '402302'];
            let transfer = 0;
            for (const key of transfer_keys) {
                if (item[key]) {
                    transfer += item[key];
                }
            }
            item.transfer = transfer;
            item.incomes = item.taxes + item.not_taxes + item.transfer + item.main_capital;
            const region = this.unReport.find(r => r.region.slice(4, 6) == '00');
            if (region) {
                const villages = this.unReport.filter(v => v.region.slice(0, 4) == region.region.slice(0, 4) && v.region.slice(4, 6) !== '00')
                let transfer = 0;
                region['402301'] = 0
                for (const vill of villages) {
                    region['402301'] += vill['402301'];
                    transfer += vill.transfer;
                }
                region.transfer = transfer;
                region.incomes = region.taxes + region.not_taxes + region.transfer + region.main_capital;
            }
            const total = this.unReport.find(r => r.region.slice(2, 6) == '0101');
            total.transfer = region.transfer;
            total['402301'] = region['402301'];
            total.incomes = total.taxes + total.not_taxes + total.transfer + total.main_capital;
        },
        async saveData(item) {
            const saveList = [];
            const toSave = this.unReport.filter(x => x.changed);
            for (const data of toSave) {
                const d = {
                    'id': data.id ? data.id : null,
                    'date': this.curParams.date_to,
                    'code': data.region,
                    'transers_tek': data['402301']
                }
                saveList.push(d)
            }
            console.log(saveList)
            const response = await fetch('/api-py/fulfillment-report/msu/data/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(saveList)
            });
            if (response.status === 200) {
                const data = await response.json();
                this.makeToast('success', 'Сохранение', 'Данные успешно сохранены');
                for (const d of data.id_list) {
                    const match = this.unReport.find(x => x.region === d.region);
                    if (match) {
                        match.id = d.id;
                        match.changed = false;
                    }
                }
            }
        },
        loadData(item) {
            this.reportOpen(true);
        }
    }
}
</script>

<style scoped>
.td-numbering {
    padding: 3px 10px !important;
    text-align: center;
    font-size: 0.7rem !important;
    font-weight: 400 !important;
    color: #6087a0 !important;
    background-color: #f7f9fc;
}
</style>