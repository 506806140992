<template>
    <div>
        <div class="filter-container">
            <b-dropdown class="filter-dropdown" variant="default" ref="drop">
                <template #button-content>
                    <span class="lc"><i class="icon icon-filter"></i> Фильтр</span><span class="rc"><i
                    class="icon icon-keyboard"></i></span>
                </template>
                <div>
                    <div class="top-content">
                        <span>Параметры фильтра</span>
                        <i class="icon icon-close" @click="$refs.drop.hide(true)"></i>
                    </div>
                    <br>
                    <b-form-group label="Наименование отчета " class="bolded-label">
                        <multiselect
                            v-model="curReport"
                            :options="reportList"
                            track-by="code"
                            label="name_ru"
                            placeholder="Выберите отчет"
                            :searchable="true"
                            :allow-empty="false"
                            :show-labels="false"
                            @input="callFilterGetObl"
                        >
                        </multiselect>
                    </b-form-group>
                    <filter-all v-if="curReport != null"
                                :repCode="curReport"
                                ref="filter"
                                @loadApplicationForm2="reportOpen2"
                                @loadApplicationForm3="reportOpen3"
                                @loadApplicationForm4="reportOpen4"
                                @loadData="reportOpen7"
                                @loadReportFormData="reportOpen542"
                                @reportIncome="repIncome"
                                @reportOutcome="repOutcome"
                                @reportControl="repControl"
                                @loadReportForm420Data="reportOpen420"
                                @loadApplication9Data="reportOpenApp9"
                                @fzp="reportFzp"
                                @budgetMsu="reportBudgetMsu"

                    >
                    </filter-all>
                </div>
            </b-dropdown>
            <b-col cols="3" md="6">
                        <b-form-radio-group
                            v-if="curReport && curReport.code === 'PR2'"
                            v-model="version"
                            :options="versions">
                        </b-form-radio-group>
            </b-col>
            <b-button v-if="curReport && curReport.code === 'PR3' && (this.access_level === 2 || this.access_level === 3)"
                      :variant="buttonVar"
                      :disabled="!isSave"
                      @click="repSaveApp3">Сохранить</b-button>
            <b-button v-if="curReport && curReport.code === 'PR2' && (this.access_level === 2 || this.access_level === 3)"
                      :variant="buttonVar"
                      :disabled="!isSave"
                      @click="repSaveApp2">Сохранить</b-button>
        </div>
        <br><br>
        <div>
            <application-form2 v-if="curReport && curReport.code === 'PR2'"  @reasonChanged="updateIsSave" ref="applicationForm2"></application-form2>
            <application-form3 v-if="curReport && curReport.code === 'PR3'"  @reasonChanged="updateIsSave" ref="applicationForm3"></application-form3>
            <application-form4 v-if="curReport && curReport.code === 'PR4'" ref="applicationForm4"></application-form4>
            <application-form7 v-if="curReport && curReport.code === 'PR7'" ref="applicationForm7"></application-form7>
            <report-form542 v-if="curReport && curReport.code === 'R542'" ref="reportForm542"></report-form542>
            <report-income v-if="curReport && curReport.code === 'income'" ref="reportIncome"></report-income>
            <report-income-downloader v-if="curReport && curReport.code === 'income-downloader'" ref="reportIncome"></report-income-downloader>
            <report-outcome v-if="curReport && curReport.code === 'outcome'" ref="reportOutcome"></report-outcome>
            <report-control v-if="curReport && curReport.code === 'control'" ref="reportControl"></report-control>
            <report-form420 v-if="curReport && curReport.code === 'R420'" ref="reportForm420"></report-form420>
            <application-form9 v-if="curReport && curReport.code === 'PR9'" ref="applicationForm9"></application-form9>
            <application-fzp v-if="curReport && curReport.code === 'FZP'" ref="fzp"></application-fzp>
            <report-budget-msu v-if="curReport && curReport.code === 'budget-msu'" ref="budgetMsu"></report-budget-msu>
        </div>
    </div>
</template>


<script>
import applicationForm2 from "@/modules/budget/monitoring/report-components/application-form-2";
import applicationForm4 from "@/modules/budget/monitoring/report-components/application-form-4";
import reportIncome from "@/modules/budget/monitoring/report-components/report-income";
import applicationForm3 from "@/modules/budget/monitoring/report-components/application-form-3";
import applicationForm7 from "@/modules/budget/monitoring/report-components/application-form-7";
import reportForm542 from "@/modules/budget/monitoring/report-components/report-form-542";
import filterAll from "@/modules/budget/monitoring/report-components/filter";
import reportOutcome from "@/modules/budget/monitoring/report-components/report-outcome";
import reportControl from "@/modules/budget/monitoring/report-components/report-control";
import reportIncomeDownloader from "@/modules/budget/monitoring/report-components/report-income-downloader";
import reportForm420 from "@/modules/budget/monitoring/report-components/report-form-420.vue";
import applicationForm9 from "@/modules/budget/monitoring/report-components/applcation-form-9.vue";
import applicationFzp from "@/modules/budget/monitoring/report-components/application-form-fzp.vue";
import reportBudgetMsu from '@/modules/budget/monitoring/report-components/application-form-budget-msu.vue'

export default {
    components: {filterAll, applicationForm2,  applicationForm3, applicationForm4, applicationForm7, reportForm542, reportIncome, reportOutcome, reportControl, reportIncomeDownloader, reportForm420, applicationForm9, applicationFzp, reportBudgetMsu},
    name: 'report-fulfill',
    data() {
        return {
            year: new Date().getFullYear(),
            month: new Date().getMonth() - 1,
            reportList: {},
            curReport: null,
            isOpen: false,
            access_level: 1,
            padding: {},
            isSave: false,
            version: 0,
            versions: [
                { value: 0, text: 'Причины неисполнения' },
                { value: 1, text: 'Причины перевыполнения' }
            ],
        };
    },

    async mounted() {
        await this.getReportList();
        await this.getAccessLevel();
    },
    computed: {
        buttonVar() {
            return this.isSave ? "success" : "secondary";
        }
    },
    watch: {
        version(newVersion) {
            this.$refs.applicationForm2.version = newVersion;
        },
    },
    methods: {
        async getAccessLevel() {
            try {
                const response = await fetch(`/api-py/user-modules/${this.$store.getters.user_uuid}/005.003.006`);

                const modules_access = await response.json();
                if (modules_access.length > 1) {
                    this.makeToast("danger", "Ошибка", "У пользователя несколько записей")
                }
                if (modules_access.length === 0) {
                    this.access_level = 1;
                } else {
                    for (const ma of modules_access) {
                        this.access_level = ma.access_level;
                    }
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса load_user_access_level', error.toString());
            }
        },
        updateIsSave() {
            this.isSave = true;
        },
        repSaveApp3() {
            this.$refs.applicationForm3.repSave();
        },
        repSaveApp2() {
            this.$refs.applicationForm2.repSave();
        },
        callFilterGetObl() {
            if (this.curReport !== null && this.$refs.filter){
                this.$refs.filter.getObl();
                this.$refs.filter.period = '';
                this.$refs.filter.currYear = 2023;
            }
        },
        async getReportList() {
            const response = await fetch(`/api-py/get-report-list`);
            this.reportList = await response.json();
            const getPR4 = (element) => element.code === 'PR4';
            const idxPR4 = this.reportList.findIndex(getPR4);
            const PR4 = this.reportList[idxPR4];
            let i;
            for (i = idxPR4 - 1; i > 1; i--) {
                this.reportList[i + 1] = this.reportList[i]
            }
            this.reportList[2] = PR4;
        },
        reportOpen2(reg, reg_name, period, date_to, qvartal, year, obl){
            this.$refs.applicationForm2.$emit('loadApplication2', reg,  reg_name, period, date_to, qvartal, year, obl);
        },
        reportOpen4(reg, reg_name, period, date_to, qvartal, year, obl){
            this.$refs.applicationForm4.$emit('loadApplication4', reg,  reg_name, period, date_to, qvartal, year, obl);
        },
        reportFzp(reg, reg_name, period, date_to, qvartal, year, obl, uuid, typeFZP, newFZP, rbFZP){
            this.$refs.fzp.$emit('fzp', reg,  reg_name, period, date_to, qvartal, year, obl, uuid, typeFZP, newFZP, rbFZP);
        },
        reportBudgetMsu(reg, reg_name, period, date_to, qvartal, year, obl, uuid, sheet, month){
            this.$refs.budgetMsu.$emit('budgetMsu', reg,  reg_name, period, date_to, qvartal, year, obl, uuid, sheet, month);
        },
        repIncome(reg, reg_name, period, date_to, month, year, obl, convert){
            this.$refs.reportIncome.$emit('report-income', reg, reg_name, period, date_to, month, year, obl, convert);
        },
        repOutcome(reg, reg_name, period, date_to, month, year, obl, convert){
            this.$refs.reportOutcome.$emit('report-outcome', reg, reg_name, period, date_to, month, year, obl, convert);
        },
        repControl(reg, reg_name, period, date_to, month, year, obl, convert){
            this.$refs.reportControl.$emit('report-control', reg, reg_name, period, date_to, month, year, obl, convert);
        },
        reportOpen3(reg, reg_name, period, date_to, qvartal, year, obl){
            this.$refs.applicationForm3.$emit('loadApplication3', reg,  reg_name, period, date_to, qvartal, year, obl);
        },
        reportOpen7(reg, reg_name, period, date_to, month, year, abp, prg, ppr, spf, uuid, obl) {
            this.$refs.applicationForm7.$emit('loadData', reg,  reg_name, period, date_to, month, year, abp, prg, ppr, spf, uuid, obl);
        },
        reportOpen542(period, date_to, month, year, region, reg_name, cutness, vzk, develop, obl, uuid, reg_switch, abpL, prgL, pprL, spfL) {
            this.$refs.reportForm542.$emit('loadReportFormData', period, date_to, month, year, region, reg_name,
                cutness, vzk, develop, obl, uuid, reg_switch, abpL, prgL, pprL, spfL);
        },
        reportOpen420(region, date_to, source, cutness, comparedPeriod, uuid, selectedABP420, selectedGU420, is0000Check, showOnlyErrors) {
            this.$refs.reportForm420.$emit('reportForm420', region, date_to, source, cutness, comparedPeriod, uuid, selectedABP420, selectedGU420, is0000Check, showOnlyErrors);
        },
        reportOpenApp9(region, date_to, cutness, uuid, period, convert) {
          this.$refs.applicationForm9.$emit('reportApplication9', region, date_to, cutness, uuid, period, convert);
        },
        repOpen(data) {
            this.isOpen = data;
        },
        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        }, // сообщение

    },
};
</script>

<style scoped>

</style>