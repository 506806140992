import { render, staticRenderFns } from "./application-form-budget-msu-svod.vue?vue&type=template&id=b85f2754&scoped=true&"
import script from "./application-form-budget-msu-svod.vue?vue&type=script&lang=js&"
export * from "./application-form-budget-msu-svod.vue?vue&type=script&lang=js&"
import style0 from "./application-form-budget-msu-svod.vue?vue&type=style&index=0&id=b85f2754&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b85f2754",
  null
  
)

export default component.exports