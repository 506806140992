<template>
    <div>
        <div class="table-container">
            <table
                class="table b-table table-bordered b-table-no-border-collapse sticky-header"
            >
                <thead class="thead-light" style="position: sticky; top: 40px">
                    <tr>
                        <th v-for="header of tableFields" :key="header.key">
                            <template v-if="header.key === 'num'">
                                <b-button @click="openAll()">
                                    <i
                                        class="icon icon-chevron-circle icon-rotate-180"
                                        v-if="open"
                                    ></i>
                                    <i
                                        class="icon icon-chevron-circle"
                                        v-if="!open"
                                    ></i>
                                </b-button>
                            </template>
                            <template v-else-if="header.key === 'action'">
                                <b-dropdown dropleft class="more-dropdown">
                                    <template v-slot:button-content>
                                        <i class="icon icon-more"></i>
                                    </template>
                                    <template>
                                        <b-dropdown-item @click="saveData">
                                            Сохранить
                                        </b-dropdown-item>
                                    </template>
                                </b-dropdown>
                            </template>
                            <template v-else>
                                {{ header.label }}
                            </template>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td
                            v-for="(header, index) of tableFields"
                            :key="'header-' + header.key"
                            class="td-numbering"
                        >
                            <div
                                class="text-center w-100px"
                                v-if="index !== 0 && header.key !== 'action'"
                            >
                                {{ index }}
                            </div>
                        </td>
                    </tr>
                    <tr
                        v-for="(item, index) of unReport"
                        :key="`${index}_${item.name}`"
                    >
                        <template>
                            <td
                                v-for="header of tableFields"
                                :key="'body-' + header.key"
                                style="
                                    padding: 0.75rem;
                                    font-size: 0.7777777778rem;
                                    font-weight: 500;
                                    line-height: 1.3;
                                    color: #0d1a2e !important;
                                    text-transform: none;
                                "
                                :style="
                                    item.region.endsWith('00')
                                        ? 'background-color: rgba(176, 224, 255, .5)!important;'
                                        : item.dataType === 2
                                        ? 'background-color: #d6d8db !important;'
                                        : ''
                                "
                            >
                                <template v-if="header.key === 'num'">
                                    <b-button
                                        v-if="item.region.endsWith('00')"
                                        @click="openChilds(item)"
                                        v-model="item.open"
                                    >
                                        <i
                                            class="icon icon-chevron-circle icon-rotate-180"
                                            v-if="item.open"
                                        ></i>
                                        <i
                                            class="icon icon-chevron-circle"
                                            v-if="!item.open"
                                        ></i>
                                    </b-button>
                                </template>
                                <template v-if="header.key === 'name'">
                                    <div v-if="item.region.slice(2, 6) !== '0101'" class="text-left w-200px">
                                        {{ item.region }} - {{ item.name }}
                                    </div>
                                    <div v-else class="text-left w-200px">
                                        {{ item.name }}
                                    </div>
                                </template>
                                <template
                                    v-else-if="header.key === 'gatherings'"
                                >
                                    <div v-if="item.region.slice(2, 6) !== '0101'" class="w-150px">
                                        <b-form-input
                                            v-model.number="item.gatherings"
                                            :type="'number'"
                                            @input="updateData(item)"
                                            min="0"
                                        ></b-form-input>
                                    </div>
                                    <div v-else>
                                        {{ $n(item.gatherings) }}
                                    </div>
                                </template>
                                <template v-else-if="header.key === 'meetings'">
                                    <div v-if="item.region.slice(2, 6) !== '0101'" class="w-150px">
                                        <b-form-input
                                            v-model.number="item.meetings"
                                            :type="'number'"
                                            @input="updateData(item)"
                                            min="0"
                                        ></b-form-input>
                                    </div>
                                    <div v-else>
                                        {{ $n(item.meetings) }}
                                    </div>
                                </template>
                                <template
                                    v-else-if="header.key === 'participants'"
                                >
                                    <div v-if="item.region.slice(2, 6) !== '0101'" class="w-150px">
                                        <b-form-input
                                            v-model.number="item.participants"
                                            :type="'number'"
                                            @input="updateData(item)"
                                            min="0"
                                        ></b-form-input>
                                    </div>
                                    <div v-else>
                                        {{ $n(item.participants) }}
                                    </div>
                                </template>
                                <template v-else-if="header.key === 'members'">
                                    <div v-if="item.region.slice(2, 6) !== '0101'" class="w-150px">
                                        <b-form-input
                                            v-model.number="item.members"
                                            :type="'number'"
                                            @input="updateData(item)"
                                            min="0"
                                        ></b-form-input>
                                    </div>
                                    <div v-else>
                                        {{ $n(item.members) }}
                                    </div>
                                </template>
                            </td>
                        </template>
                    </tr>
                </tbody>
            </table>
        </div>

        <!-- <div class="actions-tab in-tab">
            <b-dropdown variant="link" right toggle-class="text-decoration-none" no-caret>
                <template #button-content >
                    <div class="btn-download">
                        <div>
                            <span class="left">
                                <i class="icon icon-download"></i> Скачать
                            </span>
                            <i class="icon icon-keyboard"></i>
                        </div>
                    </div>
                </template>
                <b-dropdown-item @click="downloadRep(curReport)">{{repName}}</b-dropdown-item>
            </b-dropdown>
        </div> -->
        <div>
            <loading
                :active="loading"
                is-full-screen
                spinner="bar-fade-scale"
                color="#6495ED"
            />
        </div>
    </div>
</template>
<script>
import { Ax } from '@/utils';
import moment from 'moment';
import VueElementLoading from "vue-element-loading";
import { type } from '@amcharts/amcharts4/core';

export default {
    name: "application-form-budget-msu-meeting",
    components: { 'loading': VueElementLoading },
    props: {
        params: {}
    },
    data() {
        return {
            sheet: null,
            obl: null,
            access_level: 1,
            listReg: [],
            curParams: {},
            reg: null,
            reg_name: null,
            qvartal: null,
            year: null,
            curDate: null,
            dateTo: null,
            repCode: 'budget-msu',
            repName: 'Сводная информация по бюджетам МСУ',
            loading: false,
            tableFields: [
                {
                    key: 'num',
                    label: '№'
                },
                {
                    key: 'name',
                    label: 'Райгород'
                },
                {
                    key: 'gatherings',
                    label: 'Количество сходов местных сообществ'
                },
                {
                    key: 'meetings',
                    label: 'Количество собраний местных сообществ'
                },
                {
                    key: 'participants',
                    label: 'Общее количество участников'
                },
                {
                    key: 'members',
                    label: 'Общее количество членов собрания'
                },
                {
                    key: 'action',
                    label: ' '
                }
            ],
            unReport: [],
            curTab: 0,
            open: true
        };
    },

    async mounted() {
        await this.getAccessLevel();
        this.$watch('params', () => {
            this.reportOpen()
        })
    },
    created() {
        this.$on('budgetMsu', (reg, reg_name, period, date_to, qvartal, year, obl, uuid, sheet) => {
            console.log(reg)
            this.sheet = sheet;
            this.reportOpen();
            this.reg = reg;
            this.year = year;
            this.qvartal = qvartal;
            this.reg_name = reg_name;
            this.dateTo = moment(date_to).format('YYYY-MM-DD');
            this.sheet = sheet;
        });
    },
    methods: {
        async getAccessLevel() {
            try {
                const response = await fetch(`/api-py/user-modules/${this.$store.getters.user_uuid}/005.003.006`);

                const modules_access = await response.json();
                if (modules_access.length > 1) {
                    this.makeToast("danger", "Ошибка", "У пользователя несколько записей")
                }
                if (modules_access.length === 0) {
                    this.access_level = 1;
                } else {
                    for (const ma of modules_access) {
                        this.access_level = ma.access_level;
                    }
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса load_user_access_level', error.toString());
            }
        },
        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        },
        async reportOpen() {
            this.loading = true;
            this.reg = this.params.reg;
            this.unReport = [];
            this.curParams = {
                region: this.params.reg,
                period: this.params.period,
                date_to: moment(this.params.date_to).format('YYYY-MM-DD'),
                qvartal: this.params.qvartal,
                year: this.params.year,
                report: false,
                form: this.repCode,
                obl: this.params.obl,
                uuid: this.params.uuid,
                sheet: this.params.sheet.code,
                update: false
            };
            Ax(
                {
                    url: `/api-py/get-fulldata-budget-msu/` + JSON.stringify(this.curParams),
                    method: 'GET'
                },
                (data) => {
                    if (data.unReport.length === 0) {
                        this.makeToast('info', 'Внимание', 'Данных по таким параметрам нет.');
                    } else {
                        const total = {
                            'name': 'Всего',
                            'region': this.curParams.obl + '0101',
                            'gatherings': 0,
                            'meetings': 0,
                            'participants': 0,
                            'members': 0
                        }
                        this.unReport = data.unReport.sort((a, b) => a.sort_name > b.sort_name);
                        for (const item of this.unReport) {
                            for (const key of Object.keys(item)) {
                                if (typeof item[key] === 'number') {
                                    if (total[key]) {
                                        total[key] += item[key];
                                    } else {
                                        total[key] = item[key];
                                    }
                                }
                            }
                        }
                        this.unReport.unshift(total)
                    }
                    this.curTab = 0;
                    this.loading = false;
                },
                (error) => {
                    this.makeToast('danger', 'Ошибка запроса', error.toString());
                    this.loading = false;
                }
            )
        },
        openAll() {
            this.open = !this.open;
            for (const r of this.unReport.filter(x => x.region.slice(4, 6) != '00')) {
                r.visible = this.open;
            }
            for (const r of this.unReport.filter(x => x.region.slice(4, 6) == '00')) {
                r.open = this.open;
            }
        },
        openChilds(item) {
            item.open = !item.open;
            for (const r of this.unReport.filter(x => x.region !== item.region && x.region.slice(0, 4) === item.region.slice(0, 4))) {
                r.visible = item.open;
            }
        },
        rowClass(item, type) {
            if (!item || type !== 'row') {
                return;
            }
            if (!item.visible) {
                return 'is-hidden';
            }
        },
        updateData(dat) {
            dat.changed = true;
            const total = {
                'name': 'Всего',
                'region': this.curParams.obl + '0101',
                'gatherings': 0,
                'meetings': 0,
                'participants': 0,
                'members': 0
            }
            for (const item of this.unReport.filter(x => x.region.slice(2, 6) !== '0101')) {
                total.gatherings += item.gatherings;
                total.meetings += item.meetings;
                total.participants += item.participants;
                total.members += item.members;
            }
            this.unReport[0] = total;
        },
        async saveData() {
            const saveList = [];
            for (const data of this.unReport.filter(x => x.changed)) {
                const d = {
                    'id': data.id,
                    'date': this.curParams.date_to,
                    'region': data.region,
                    'gatherings': data.gatherings,
                    'meetings': data.meetings,
                    'participants': data.participants,
                    'members': data.members
                }
                saveList.push(d)
            }
            console.log(saveList)
            const response = await fetch('/api-py/fulfillment-report/meeting/data/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(saveList)
            });
            if (response.status === 200) {
                const data = await response.json();
                this.makeToast('success', 'Сохранение', 'Данные успешно сохранены');
                for (const d of data.id_list) {
                    const match = this.unReport.find(x => x.region === d.region);
                    if (match) {
                        match.id = d.id;
                        match.changed = false;
                    }
                }
            }
        },
    }
}
</script>

<style scoped>
.td-numbering {
    padding: 3px 10px !important;
    text-align: center;
    font-size: 0.7rem !important;
    font-weight: 400 !important;
    color: #6087a0 !important;
    background-color: #f7f9fc;
}
</style>