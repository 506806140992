<template>
    <div>
        <div class="table-container">
            <table
                class="table b-table table-bordered b-table-no-border-collapse sticky-header"
            >
                <thead class="thead-light" style="position: sticky; top: 40px">
                    <tr>
                        <th v-for="header of tableFields" :key="header.key">
                            <template v-if="header.key === 'num'">
                                <b-button @click="openAll()">
                                    <i
                                        class="icon icon-chevron-circle icon-rotate-180"
                                        v-if="open"
                                    ></i>
                                    <i
                                        class="icon icon-chevron-circle"
                                        v-if="!open"
                                    ></i>
                                </b-button>
                            </template>
                            <template v-else>
                                {{ header.label }}
                            </template>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td
                            v-for="(header, index) of tableFields"
                            :key="'header-' + header.key"
                            class="td-numbering"
                        >
                            <div
                                class="text-center w-100px"
                                v-if="index !== 0 && header.key !== 'action'"
                            >
                                {{ index }}
                            </div>
                        </td>
                    </tr>
                    <tr
                        v-for="(item, index) of unReport"
                        :key="`${index}_${item.name}`"
                    >
                        <template v-if="item.visible">
                            <td
                                v-for="header of tableFields"
                                :key="'body-' + header.key"
                                style="
                                    padding: 0.75rem;
                                    font-size: 0.7777777778rem;
                                    font-weight: 500;
                                    line-height: 1.3;
                                    color: #0d1a2e !important;
                                    text-transform: none;
                                "
                                :style="
                                    item.region.endsWith('00')
                                        ? 'background-color: rgba(176, 224, 255, .5)!important;'
                                        : item.dataType === 2
                                        ? 'background-color: #d6d8db !important;'
                                        : ''
                                "
                            >
                                <template v-if="header.key === 'num'">
                                    <b-button
                                        v-if="item.region.endsWith('00')"
                                        @click="openChilds(item)"
                                        v-model="item.open"
                                    >
                                        <i
                                            class="icon icon-chevron-circle icon-rotate-180"
                                            v-if="item.open"
                                        ></i>
                                        <i
                                            class="icon icon-chevron-circle"
                                            v-if="!item.open"
                                        ></i>
                                    </b-button>
                                </template>
                                <template v-if="header.key === 'name'">
                                    <div
                                        v-if="item.region.endsWith('00') || item.region.endsWith('01')"
                                        class="text-left w-200px"
                                    >
                                        {{ item.name }}
                                    </div>
                                    <div v-else class="text-left w-200px">
                                        {{ item.region }} - {{ item.name }}
                                    </div>
                                </template>
                                <template v-else-if="header.key === 'outcomes'">
                                    <div>
                                        {{ $n(item.outcomes) }}
                                    </div>
                                </template>
                                <template v-else-if="header.key === 'count'">
                                    <div
                                        class="w-150px"
                                        v-if="
                                            !reg.includes('0101') &&
                                            item.region.slice(4, 6) !== '00' && item.region.slice(4, 6) !== '01'
                                        "
                                    >
                                        <b-form-input
                                            v-model.number="item.count"
                                            :type="'number'"
                                            @input="updateData(item)"
                                            min="0"
                                        ></b-form-input>
                                    </div>
                                    <div v-else>
                                        {{ $n(item.count) }}
                                    </div>
                                </template>
                                <template v-else-if="header.key === 'incomes'">
                                    <div>
                                        {{ $n(item.incomes) }}
                                    </div>
                                </template>
                                <template
                                    v-else-if="header.key === 'withdrawals'"
                                >
                                    <div>
                                        {{ $n(item.withdrawals) }}
                                    </div>
                                </template>
                                <template
                                    v-else-if="header.key === 'subventions'"
                                >
                                    <div>
                                        {{ $n(item.subventions) }}
                                    </div>
                                </template>
                                <template v-else-if="header.key === 'percent'">
                                    <div>
                                        {{
                                            $n(
                                                Math.round(
                                                    (item.subventions /
                                                        item.incomes) *
                                                        1000
                                                ) / 10
                                            )
                                        }}
                                    </div>
                                </template>
                                <template v-else-if="header.key === 'action'">
                                    <div
                                        v-if="
                                            !reg.includes('0101') &&
                                            item.region.slice(4, 6) === '00'
                                        "
                                    >
                                        <b-dropdown
                                            dropleft
                                            class="more-dropdown"
                                        >
                                            <template v-slot:button-content>
                                                <i class="icon icon-more"></i>
                                            </template>
                                            <template>
                                                <b-dropdown-item
                                                    @click="saveData(item)"
                                                >
                                                    Сохранить
                                                </b-dropdown-item>
                                            </template>
                                        </b-dropdown>
                                    </div>
                                </template>
                            </td>
                        </template>
                    </tr>
                </tbody>
            </table>
        </div>
        <div>
            <loading
                :active="loading"
                is-full-screen
                spinner="bar-fade-scale"
                color="#6495ED"
            />
        </div>
    </div>
</template>
<script>
import { Ax } from '@/utils';
import moment from 'moment';
import VueElementLoading from "vue-element-loading";
import { type } from '@amcharts/amcharts4/core';

export default {
    name: "application-form-budget-msu-svod",
    components: { 'loading': VueElementLoading },
    props: {
        params: {}
    },
    data() {
        return {
            sheet: null,
            obl: null,
            access_level: 1,
            listReg: [],
            curParams: {},
            reg: null,
            reg_name: null,
            qvartal: null,
            year: null,
            curDate: null,
            dateTo: null,
            repCode: 'budget-msu',
            repName: 'Сводная информация по бюджетам МСУ (Сводная)',
            loading: false,
            tableFields: [
                {
                    key: 'num',
                    label: '№'
                },
                {
                    key: 'name',
                    label: 'Райгород'
                },
                {
                    key: 'count',
                    label: 'Численность населения (чел)'
                },
                {
                    key: 'incomes',
                    label: 'Доходы'
                },
                {
                    key: 'subventions',
                    label: 'субвенции'
                },
                {
                    key: 'percent',
                    label: '%'
                },
                {
                    key: 'outcomes',
                    label: 'Затраты'
                },
                {
                    key: 'withdrawals',
                    label: 'изъятия'
                },
                {
                    key: 'action',
                    label: ' '
                }
            ],
            unReport: [],
            curTab: 0,
            open: true
        };
    },

    async mounted() {
        await this.getAccessLevel();
        this.$watch('params', () => {
            this.reportOpen()
        })
    },
    created() {
        this.$on('budgetMsu', (reg, reg_name, period, date_to, qvartal, year, obl, uuid, sheet) => {
            console.log(reg)
            this.sheet = sheet;
            this.reportOpen();
            this.reg = reg;
            this.year = year;
            this.qvartal = qvartal;
            this.reg_name = reg_name;
            this.dateTo = moment(date_to).format('YYYY-MM-DD');
            this.sheet = sheet;
        });
    },
    methods: {
        async getAccessLevel() {
            try {
                const response = await fetch(`/api-py/user-modules/${this.$store.getters.user_uuid}/005.003.006`);

                const modules_access = await response.json();
                if (modules_access.length > 1) {
                    this.makeToast("danger", "Ошибка", "У пользователя несколько записей")
                }
                if (modules_access.length === 0) {
                    this.access_level = 1;
                } else {
                    for (const ma of modules_access) {
                        this.access_level = ma.access_level;
                    }
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса load_user_access_level', error.toString());
            }
        },
        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        },
        async reportOpen() {
            this.loading = true;
            this.reg = this.params.reg;
            this.unReport = [];
            this.curParams = {
                region: this.params.reg,
                period: this.params.period,
                date_to: moment(this.params.date_to).format('YYYY-MM-DD'),
                qvartal: this.params.qvartal,
                year: this.params.year,
                report: false,
                form: this.repCode,
                obl: this.params.obl,
                uuid: this.params.uuid,
                sheet: this.params.sheet.code,
                update: false
            };
            Ax(
                {
                    url: `/api-py/get-fulldata-budget-msu/` + JSON.stringify(this.curParams),
                    method: 'GET'
                },
                (data) => {
                    if (data.income.length === 0 && data.outcome.length === 0) {
                        this.makeToast('info', 'Внимание', 'Данных по таким параметрам нет.');
                    } else {
                        const total = {
                            name: 'Всего',
                            region: this.curParams.obl + '0101',
                            visible: true,
                            count: 0,
                            withdrawals: 0,
                            incomes: 0,
                            subventions: 0,
                            outcomes: 0
                        };
                        const incomeList = [];
                        const outcomeList = [];
                        for (const inc of data.income.sort((a, b) => a.sort_name > b.sort_name)) {
                            const i = {
                                name: inc.name,
                                region: inc.code
                            }
                            const region_list = []
                            for (const child of inc.childs.sort((a, b) => a.sort_name > b.sort_name)) {
                                const attr = child.code;
                                if (i[attr]) {
                                    i[attr] += child.value;
                                } else {
                                    i[attr] = child.value;
                                }
                                const c = {
                                    region: child.region,
                                    name: child.name
                                }
                                if (!region_list.some(element => element.region === c.region)) {
                                    region_list.push(c);
                                }
                            }
                            const transport_keys = ['104401', '104402']
                            let transport = 0;
                            for (const key of transport_keys) {
                                if (i[key]) {
                                    transport += i[key]
                                }
                            }
                            i['104401'] = transport;
                            const transfer_keys = ['402303', '402301', '402302'];
                            let transfer = 0;
                            for (const key of transfer_keys) {
                                if (i[key]) {
                                    transfer += i[key];
                                }
                            }
                            i.transfer = transfer;
                            const not_taxes_key = ['201510', '206115', '204170', '206109'];
                            let not_taxes = 0;
                            for (const key of not_taxes_key) {
                                if (i[key]) {
                                    not_taxes += i[key];
                                }
                            }
                            i.not_taxes = not_taxes;
                            const taxes_key = ['101202', '104102', '104302', '104401', '105315', '104501', '105430'];
                            let taxes = 0;
                            for (const key of taxes_key) {
                                if (i[key]) {
                                    taxes += i[key];
                                }
                            }
                            i.taxes = taxes;
                            const main_capital_key = ['303101', '303202', '301102'];
                            let main_capital = 0;
                            for (const key of main_capital_key) {
                                if (i[key]) {
                                    main_capital += i[key];
                                }
                            }
                            i.main_capital = main_capital;
                            const income = taxes + not_taxes + transfer + main_capital;
                            i.incomes = income;
                            i.visible = true;
                            i.open = true;
                            incomeList.push(i)
                            for (const reg of region_list) {
                                const child_reg = inc.childs.filter((a) => a.region === reg.region);
                                if (child_reg.length > 0) {
                                    for (const item of child_reg) {
                                        const attr = item.code;
                                        if (reg[attr]) {
                                            reg[attr] += item.value;
                                        } else {
                                            reg[attr] = item.value;
                                        }
                                    }
                                }
                                let transport = 0;
                                for (const key of transport_keys) {
                                    if (reg[key]) {
                                        transport += reg[key]
                                    }
                                }
                                reg['104401'] = transport;
                                let transfer = 0
                                for (const key of transfer_keys) {
                                    if (reg[key]) {
                                        transfer += reg[key];
                                    }
                                }
                                reg.transfer = transfer;
                                let not_taxes = 0;
                                for (const key of not_taxes_key) {
                                    if (reg[key]) {
                                        not_taxes += reg[key];
                                    }
                                }
                                reg.not_taxes = not_taxes;
                                let taxes = 0;
                                for (const key of taxes_key) {
                                    if (reg[key]) {
                                        taxes += reg[key];
                                    }
                                }
                                reg.taxes = taxes;
                                let main_capital = 0;
                                for (const key of main_capital_key) {
                                    if (reg[key]) {
                                        main_capital += reg[key];
                                    }
                                }
                                reg.main_capital = main_capital;
                                const income = taxes + not_taxes + transfer + main_capital;
                                reg.incomes = income;
                                reg.visible = true;
                                incomeList.push(reg);
                            }
                        }
                        for (const out of data.outcome.sort((a, b) => a.sort_name > b.sort_name)) {
                            const i = {
                                name: out.name,
                                region: out.code
                            }
                            const region_list = []
                            for (const child of out.childs.sort((a, b) => a.sort_name > b.sort_name)) {
                                const attr = child.code;
                                if (i[attr]) {
                                    i[attr] += child.value;
                                } else {
                                    i[attr] = child.value;
                                }
                                const c = {
                                    region: child.region,
                                    name: child.name
                                }
                                if (!region_list.some(element => element.region === c.region)) {
                                    region_list.push(c);
                                }
                            }
                            const func_app_keys = ['1', '22', '32']
                            let func_app = 0;
                            for (const key of func_app_keys) {
                                if (i[key]) {
                                    func_app += i[key];
                                }
                            }
                            i.func_app = func_app;
                            const improvement_keys = ['11']
                            let improvement = 0;
                            for (const key of improvement_keys) {
                                if (i[key]) {
                                    improvement += i[key];
                                }
                            }
                            i.improvement = improvement;
                            const construction_keys = ['13', '45']
                            let construction = 0;
                            for (const key of construction_keys) {
                                if (i[key]) {
                                    construction += i[key];
                                }
                            }
                            i.construction = construction;
                            const water_keys = ['14']
                            let water = 0;
                            for (const key of water_keys) {
                                if (i[key]) {
                                    water += i[key];
                                }
                            }
                            i.water = water;
                            const light_keys = ['8']
                            let light = 0;
                            for (const key of light_keys) {
                                if (i[key]) {
                                    light += i[key];
                                }
                            }
                            i.light = light;
                            const social_keys = ['3']
                            let social = 0;
                            for (const key of social_keys) {
                                if (i[key]) {
                                    social += i[key];
                                }
                            }
                            i.social = social;
                            const housing_keys = ['7']
                            let housing = 0;
                            for (const key of housing_keys) {
                                if (i[key]) {
                                    housing += i[key];
                                }
                            }
                            i.housing = housing;
                            const sanitar_keys = ['9']
                            let sanitar = 0;
                            for (const key of sanitar_keys) {
                                if (i[key]) {
                                    sanitar += i[key];
                                }
                            }
                            i.sanitar = sanitar;
                            const burial_keys = ['10']
                            let burial = 0;
                            for (const key of burial_keys) {
                                if (i[key]) {
                                    burial += i[key];
                                }
                            }
                            i.burial = burial;
                            const initiat_keys = ['25']
                            let initiat = 0;
                            for (const key of initiat_keys) {
                                if (i[key]) {
                                    initiat += i[key];
                                }
                            }
                            i.initiat = initiat;
                            const culture_keys = ['6']
                            let culture = 0;
                            for (const key of culture_keys) {
                                if (i[key]) {
                                    culture += i[key];
                                }
                            }
                            i.culture = culture;
                            const sport_keys = ['28']
                            let sport = 0;
                            for (const key of sport_keys) {
                                if (i[key]) {
                                    sport += i[key];
                                }
                            }
                            i.sport = sport;
                            const snp_keys = ['57']
                            let snp = 0;
                            for (const key of snp_keys) {
                                if (i[key]) {
                                    snp += i[key];
                                }
                            }
                            i.snp = snp;
                            const transfert_keys = ['48']
                            let transfert = 0;
                            for (const key of transfert_keys) {
                                if (i[key]) {
                                    transfert += i[key];
                                }
                            }
                            i.transfert = transfert;
                            const other_keys = ['106', '107', '42', '53', '43']
                            let other = 0;
                            for (const key of other_keys) {
                                if (i[key]) {
                                    other += i[key];
                                }
                            }
                            i.other = other;
                            const withdrawals_keys = ['43'];
                            let withdr = 0;
                            for (const key of withdrawals_keys) {
                                if (i[key]) {
                                    withdr += i[key];
                                }
                            }
                            i.withdrawals = withdr;
                            const outcomes_keys = ['func_app', 'improvement', 'construction',
                                'water', 'light', 'housing', 'sanitar',
                                'social', 'burial', 'initiat', 'culture', 'sport', 'snp', 'transfert', 'other'];
                            let outcome = 0;
                            for (const key of outcomes_keys) {
                                if (i[key]) {
                                    outcome += i[key];
                                }
                            }
                            i.outcomes = outcome
                            i.visible = true;
                            i.open = true;
                            outcomeList.push(i)
                            for (const reg of region_list) {
                                const child_reg = out.childs.filter((a) => a.region === reg.region);
                                if (child_reg.length > 0) {
                                    for (const item of child_reg) {
                                        const attr = item.code;
                                        if (reg[attr]) {
                                            reg[attr] += item.value;
                                        } else {
                                            reg[attr] = item.value;
                                        }
                                    }
                                }
                                let other = 0;
                                for (const key of other_keys) {
                                    if (reg[key]) {
                                        other += reg[key];
                                    }
                                }
                                reg.other = other;
                                let transfert = 0;
                                for (const key of transfert_keys) {
                                    if (reg[key]) {
                                        transfert += reg[key];
                                    }
                                }
                                reg.transfert = transfert;
                                let snp = 0;
                                for (const key of snp_keys) {
                                    if (reg[key]) {
                                        snp += reg[key];
                                    }
                                }
                                reg.snp = snp;
                                let sport = 0;
                                for (const key of sport_keys) {
                                    if (reg[key]) {
                                        sport += reg[key];
                                    }
                                }
                                reg.sport = sport;
                                let culture = 0;
                                for (const key of culture_keys) {
                                    if (reg[key]) {
                                        culture += reg[key];
                                    }
                                }
                                reg.culture = culture;
                                let initiat = 0;
                                for (const key of initiat_keys) {
                                    if (reg[key]) {
                                        initiat += reg[key];
                                    }
                                }
                                reg.initiat = initiat;
                                let burial = 0;
                                for (const key of burial_keys) {
                                    if (reg[key]) {
                                        burial += reg[key];
                                    }
                                }
                                reg.burial = burial;
                                let social = 0;
                                for (const key of social_keys) {
                                    if (reg[key]) {
                                        social += reg[key];
                                    }
                                }
                                reg.social = social;
                                let sanitar = 0;
                                for (const key of sanitar_keys) {
                                    if (reg[key]) {
                                        sanitar += reg[key];
                                    }
                                }
                                reg.sanitar = sanitar;
                                let housing = 0;
                                for (const key of housing_keys) {
                                    if (reg[key]) {
                                        housing += reg[key];
                                    }
                                }
                                reg.housing = housing;
                                let light = 0;
                                for (const key of light_keys) {
                                    if (reg[key]) {
                                        light += reg[key];
                                    }
                                }
                                reg.light = light;
                                let water = 0;
                                for (const key of water_keys) {
                                    if (reg[key]) {
                                        water += reg[key];
                                    }
                                }
                                reg.water = water;
                                let improvement = 0;
                                for (const key of improvement_keys) {
                                    if (reg[key]) {
                                        improvement += reg[key];
                                    }
                                }
                                reg.improvement = improvement;
                                let construction = 0;
                                for (const key of construction_keys) {
                                    if (reg[key]) {
                                        construction += reg[key];
                                    }
                                }
                                reg.construction = construction;
                                let func_app = 0;
                                for (const key of func_app_keys) {
                                    if (reg[key]) {
                                        func_app += reg[key];
                                    }
                                }
                                reg.func_app = func_app;
                                let outcome = 0;
                                for (const key of outcomes_keys) {
                                    if (reg[key]) {
                                        outcome += reg[key];
                                    }
                                }
                                let withdr = 0;
                                for (const key of withdrawals_keys) {
                                    if (reg[key]) {
                                        withdr += reg[key];
                                    }
                                }
                                reg.withdrawals = withdr;
                                reg.outcomes = outcome;
                                reg.visible = true;
                                outcomeList.push(reg);
                            }
                        }
                        for (const out of outcomeList) {
                            const match = incomeList.find(x => x.region === out.region);
                            if (match) {
                                const dat = {
                                    id: null,
                                    name: match.name,
                                    region: match.region,
                                    count: 0,
                                    incomes: match.incomes,
                                    subventions: match['402303'] ? match['402303'] : 0,
                                    outcomes: out.outcomes,
                                    withdrawals: out['withdrawals'],
                                    visible: true
                                }
                                if (match.open) {
                                    dat.open = match.open;
                                }
                                this.unReport.push(dat);
                            } else {
                                const dat = {
                                    id: null,
                                    name: out.name,
                                    region: out.region,
                                    count: 0,
                                    incomes: 0,
                                    subventions: 0,
                                    outcomes: out.outcomes,
                                    withdrawals: out['withdrawals'],
                                    visible: true
                                }
                                if (out.open) {
                                    dat.open = out.open;
                                }
                                this.unReport.push(dat);
                            }
                        }
                        for (const count of data.count.sort((a, b) => a.sort_name > b.sort_name)) {
                            const match = this.unReport.find(x => x.region === count.region);
                            if (match) {
                                match.id = count.id;
                                match.count = count.count;
                            }
                        }
                        const region_parents = this.unReport.filter(x => x.region.slice(4, 6) === '00')
                        for (const r_parent of region_parents) {
                            r_parent.count = 0;
                            const regs = this.unReport.filter(x => x.region.slice(0, 4) === r_parent.region.slice(0, 4));
                            for (const r of regs) {
                                r_parent.count += r.count;
                            }
                            total.count += r_parent.count;
                            total.outcomes += r_parent.outcomes;
                            total.withdrawals += r_parent.withdrawals;
                            total.incomes += r_parent.incomes;
                            total.subventions += r_parent.subventions;
                        }
                        this.unReport.unshift(total)

                    }
                    this.curTab = 0;
                    this.loading = false;
                },
                (error) => {
                    this.makeToast('danger', 'Ошибка запроса', error.toString());
                    this.loading = false;
                }
            )
        },
        downloadRep() {
            try {
                this.makeToast('info', 'Внимание!', 'Скоро начнется скачивание. Пожалуйста ожидайте.');
                this.isLoad = true
                this.curParams.report = true;
                Ax(
                    {
                        url: '/api-py/fulfill_report_budget_msu/' + JSON.stringify(this.curParams),
                        method: 'POST',
                        responseType: 'blob'
                    },
                    (data) => {
                        const url = window.URL.createObjectURL(new Blob([data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', this.repName + '.xlsx');
                        document.body.appendChild(link);
                        link.click();
                    },
                    (error) => {
                        this.makeToast('danger', 'Ошибка запроса downloadRep()', error.toString());
                    }
                );
            } catch (error) {
                console.log(error)
                this.makeToast('danger', 'Предупреждение', 'Необходимо заполнить все обязательные фильтры!');
            } finally {
                this.isLoad = false
            }
        },
        openAll() {
            this.open = !this.open;
            for (const r of this.unReport.filter(x => x.region.slice(4, 6) != '00' && x.region.slice(4, 6) != '01')) {
                r.visible = this.open;
            }
            for (const r of this.unReport.filter(x => x.region.slice(4, 6) == '00')) {
                r.open = this.open;
            }
        },
        openChilds(item) {
            item.open = !item.open;
            for (const r of this.unReport.filter(x => x.region !== item.region && x.region.slice(0, 4) === item.region.slice(0, 4))) {
                r.visible = item.open;
            }
        },
        rowClass(item, type) {
            if (!item || type !== 'row') {
                return;
            }
            if (!item.visible) {
                return 'is-hidden';
            }
        },
        updateData(dat) {
            dat.changed = true;
            const total = {
                name: 'Всего',
                region: this.curParams.obl + '0101',
                visible: true,
                count: 0,
                withdrawals: 0,
                incomes: 0,
                subventions: 0,
                outcomes: 0
            };
            dat.count = Number(dat.count);
            const region_parents = this.unReport.filter(x => x.region.slice(4, 6) === '00')
            for (const r_parent of region_parents) {
                r_parent.count = 0;
                const regs = this.unReport.filter(x => x.region.slice(0, 4) === r_parent.region.slice(0, 4));
                for (const r of regs) {
                    r_parent.count += r.count;
                }
                total.count += r_parent.count;
                total.outcomes += r_parent.outcomes;
                total.withdrawals += r_parent.withdrawals;
                total.incomes += r_parent.incomes;
                total.subventions += r_parent.subventions;
            }
            this.unReport[0] = total;
        },
        async saveData(item) {
            const saveList = [];
            for (const data of this.unReport.filter(x => x.changed && (x.region.slice(0, 4) === item.region.slice(0, 4)))) {
                const d = {
                    'id': data.id,
                    'date': this.curParams.date_to,
                    'code': data.region,
                    'count': data.count,
                    'withdrawals': 0
                }
                saveList.push(d)
            }
            console.log(saveList)
            const response = await fetch('/api-py/fulfillment-report/msu/data/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(saveList)
            });
            if (response.status === 200) {
                const data = await response.json();
                this.makeToast('success', 'Сохранение', 'Данные успешно сохранены');
                for (const d of data.id_list) {
                    const match = this.unReport.find(x => x.region === d.region);
                    if (match) {
                        match.id = d.id;
                        match.changed = false;
                    }
                }
            }
        },
    }
}
</script>

<style scoped>
.td-numbering {
    padding: 3px 10px !important;
    text-align: center;
    font-size: 0.7rem !important;
    font-weight: 400 !important;
    color: #6087a0 !important;
    background-color: #f7f9fc;
}
</style>