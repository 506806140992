

























































































































































































































































































































































































































































































































































































import VueElementLoading from "vue-element-loading"
import VueDraggable from 'vuedraggable'
import { Vue, Component, Prop } from 'vue-property-decorator'
import Application2 from "@/modules/budget/monitoring/reports-constructor/report-components/application-2.vue"
import Report127 from "@/modules/budget/monitoring/reports-constructor/report-components/report-1-27.vue"
import {
    JournalCreateRequest,
    ReportParameters,
    ReportParametersWithOption,
    Application2TableData,
    Application2TableRow,
    Application2Response,
    Report127TableData,
    Report127TableBaseRow,
    Report127TableExpenseRow,
    Report127TableIncomeRow,
    Report127JournalTableData,
    Category,
    Indicator,
    Option
} from '@/modules/budget/monitoring/reports-constructor/common/types'
import { getLastDateOfQuarter, getLastDateOfMonth } from '@/modules/budget/monitoring/reports-constructor/common/utils'

const tempOption: Option = { value: '', label: '' }
const tempParams: ReportParametersWithOption = {
    id: null,
    abpSummary: false,
    budgetType: [],
    classification: tempOption,
    dataSource: tempOption,
    date: '',
    description: '',
    excludeZeroRows: false,
    separateByRegion: false,
    isShared: false,
    measureUnit: tempOption,
    budgetStructure: null,
    developType: tempOption,
    incomeTotal: false,
    expenseTotal: false,
    month: tempOption,
    mutuallyRedeeming: tempOption,
    name: '',
    periodicity: tempOption,
    quarter: tempOption,
    regions: [],
    reportName: tempOption,
    reportType: tempOption,
    roundUp: false,
    roundUpTo: 0,
    year: 0
}

const allBudgetTypesOptions: Option[] = [
    { value: 2, label: 'Областной' },
    { value: 3, label: 'Районный (городской)' },
    { value: 6, label: 'МСУ' }
]

@Component({
    components: {
        'loading': VueElementLoading,
        'draggable': VueDraggable,
        'application-2': Application2,
        'report-1-27': Report127
    }
})
export default class ParametersPanel extends Vue {
    // #region Props
    @Prop({
        type: Object,
        required: true
    })
    public templateData!: ReportParameters

    @Prop({
        type: Function,
        required: true
    })
    public onSelectReport!: any
    // #endregion

    // #region Data
    public templateName = this.templateData.name
    public loading = false
    public regionLoading = false
    public reportTitle = "Приложение 2 по 629 нпа"
    public visible = true
    public saveAsNewModal = false
    public tableKey = 1 // TODO: no need for key update
    public tableData: Application2TableData | Report127TableData | null = null
    public progress = 100
    private period: string | null = null
    public reportParameters: ReportParametersWithOption = tempParams
    public parameterOptions = {
        reportNames: [
            { value: 'PR9', label: 'Приложение 9' },
            { value: 'PR2', label: 'Приложение 2' },
            { value: '1-27', label: 'Отчет 1-27' }
        ] as Option[],
        reportTypes: [
            { value: 'REGULATED', label: 'Регламентированный' },
            { value: 'UNREGULATED', label: 'Нерегламентированный' }
        ] as Option[],
        classifications: [
            { value: 'INCOMES', label: 'Доходы' },
            { value: 'EXPENSES', label: 'Расходы' },
            { value: 'MIXED', label: 'Смешанная' },
        ] as Option[],
        regions: [] as Option[], // Задается при 'create' компонента
        mutuallyRedeeming: [
            { value: 'WITH', label: 'c ВЗК' },
            { value: 'ONLY', label: 'только ВЗК' },
            { value: 'WITHOUT', label: 'без ВЗК' }
        ] as Option[],
        dataSources: [
            { value: 'IISK', label: 'ИИСК' },
            { value: 'LOADER', label: 'Загрузчик' },
            { value: 'MIXED', label: 'Смешанный' }
        ] as Option[],
        periodicities: [
            { value: 'YEAR', label: 'годовой' },
            { value: 'QUARTER', label: 'квартальный' },
            { value: 'MONTH', label: 'месячный' }
        ] as Option[],
        budgetTypes: JSON.parse(JSON.stringify(allBudgetTypesOptions)) as Option[],
        measureUnits: [
            { value: 'TENGE', label: 'тенге' },
            { value: 'THOUSAND', label: 'тыс. тенге' }
        ] as Option[],
        budgetStructures: [
            { value: 'WITH', label: 'По структуре' },
            { value: 'WITHOUT', label: 'Без структуры' }
        ] as Option[],
        developTypes: [
            { label: 'Все', value: null }, 
            { label: 'Текущие', value: 0 }, 
            { label: 'Развития', value: 1 }
        ] as Option[],
        quarters: [
            {
                value: 1,
                label: "Квартал 1",
            },
            {
                value: 2,
                label: "Квартал 2",
            },
            {
                value: 3,
                label: "Квартал 3",
            },
            {
                value: 4,
                label: "Квартал 4",
            },
        ] as Option[],
        months: [
            {
                value: 1,
                label: "Январь",
            },
            {
                value: 2,
                label: "Февраль",
            },
            {
                value: 3,
                label: "Март",
            },
            {
                value: 4,
                label: "Апрель",
            },
            {
                value: 5,
                label: "Май",
            },
            {
                value: 6,
                label: "Июнь",
            },
            {
                value: 7,
                label: "Июль",
            },
            {
                value: 8,
                label: "Август",
            },
            {
                value: 9,
                label: "Сентябрь",
            },
            {
                value: 10,
                label: "Октябрь",
            },
            {
                value: 11,
                label: "Ноябрь",
            },
            {
                value: 12,
                label: "Декабрь",
            },
        ] as Option[],
    }
    public incomes: Category[] = [
        { key: 'kat', active: false, selected: [] },
        { key: 'cls', active: false, selected: [] },
        { key: 'pcl', active: false, selected: [] },
        { key: 'spf', active: false, selected: [] }
    ]
    public incomesToPass: Category[] = []
    public expenses: Category[] = [
        { key: 'gr',  active: false, selected: [] },
        { key: 'pgr', active: false, selected: [] },
        { key: 'abp', active: false, selected: [] },
        { key: 'gu', active: false, selected: [] },
        { key: 'prg', active: false, selected: [] },
        { key: 'ppr', active: false, selected: [] },
        { key: 'spf', active: false, selected: [] },
    ]
    public expensesToPass: Category[] = []

    public indicators: Indicator[] = []

    public categoryNameMap = {
        'kat': 'КАТ:',
        'cls': 'КЛ:',
        'pcl': 'ПКЛ:',
        'spf': 'СПФ:',
        'gr': 'ФГ:',
        'pgr': 'ФПГ:',
        'abp': 'АБП:',
        'gu': 'ГУ:',
        'prg': 'БП:',
        'ppr': 'БПП:'
    }

    public incomeOptions = {
        kat: [],
        cls: [],
        pcl: [],
        spf: [],
    }
    public expenseOptions = {
        gr: [],
        pgr: [],
        abp: [],
        gu: [],
        prg: [],
        ppr: [],
        spf: [],
    }

    // Чтобы сохранить параметры в момент нажатия 'сформировать',
    // нужно потому что показатели (indicators) задаются после формирования
    private excelParameters: any = {}
    // #endregion

    // #region Computed
    get userUiid(): string {
        return this.$store.state.user.sub;
    }

    get username(): string {
        return this.$store.state.user.preferred_username
    }

    get hasEditAccess(): boolean {
        const moduleCode = '005.003.007'
        const accessLevel = this.$store.state.user.userModules.find((it: any) => it.modules === moduleCode)?.access_level
        return accessLevel >= 2
    }

    get isStructuredBudgetPossible(): boolean {
        let isPossible: boolean = true

        // 1. Проверка по выбранности (должны быть оба)
        const hasChosenIncome = this.incomes.filter(it => it.active).length > 0
        const hasChosenExpense = this.expenses.filter(it => it.active).length > 0
        if (!hasChosenIncome || !hasChosenExpense) {
            isPossible = false
        }

        // 2. Проверка по порядку (должна быть стандартной)
        const standartIncomeOrder = ['kat', 'cls', 'pcl', 'spf']
        const currentIncomeOrder = this.incomes.map(it => it.key)
        for (let i = 0; i < standartIncomeOrder.length; i++) {
            if (standartIncomeOrder[i] !== currentIncomeOrder[i]) {
                isPossible = false
            }
        }
        const standartExpenseOrder = ['gr', 'pgr', 'abp', 'prg', 'ppr', 'spf']
        const currentExpenseOrder = this.expenses.map(it => it.key)
        for (let i = 0; i < standartExpenseOrder.length; i++) {
            if (standartExpenseOrder[i] !== currentExpenseOrder[i]) {
                isPossible = false
            }
        }

        // Side-effect костыль (чтобы не создавать watch)
        if (!isPossible) {
            this.reportParameters.budgetStructure = this.parameterOptions.budgetStructures.find(it => it.value === 'WITHOUT')!!
        }

        return isPossible
    }
    // #endregion

    // #region Lifecycles
    private async created() {
        this.loading = true
        await this.setRegionOptionsOnCreate()
        await this.setCategoryOptions()
        this.setReportParameters()
        this.loading = false
    }
    // #endregion

    // #region Methods
    public toggleAbpSummary() {
        this.reportParameters.abpSummary = !this.reportParameters.abpSummary
    }

    public onBackToTemplatesList() {
        this.$emit("set-journal-params", null)
        this.onSelectReport(null)
    }

    public onRegionSelect(regionOptions: Option[]) {
        this.reportParameters.budgetType = []
        // Проставить соответсвующий budget_type
        const budgetTypes: number[] = []
        regionOptions.forEach(regionOption => {
            const code: string = regionOption.value as string
            if (['302001', '301901', '300401'].includes(code) && this.templateData.reportName === 'PR2') {
                if (budgetTypes.notIncludes(3)) {
                    budgetTypes.push(3)
                }
            } else if (code.substring(2) === '0000') {
                if (budgetTypes.notIncludes(2)) {
                    budgetTypes.push(2)
                }
                if (budgetTypes.notIncludes(3)) {
                    budgetTypes.push(3)
                }
                if (budgetTypes.notIncludes(6)) {
                    budgetTypes.push(6)
                }
            } else if (code.substring(4) === '00') {
                if (budgetTypes.notIncludes(3)) {
                    budgetTypes.push(3)
                }
                if (budgetTypes.notIncludes(6)) {
                    budgetTypes.push(6)
                }
            } else if (code.substring(2) === '0101') {
                if (budgetTypes.notIncludes(2)) {
                    budgetTypes.push(2)
                }
            } else if (code.substring(4) === '01') {
                if (budgetTypes.notIncludes(3)) {
                    budgetTypes.push(3)
                }
            } else if (code.substring(4) !== '01') {
                if (budgetTypes.notIncludes(6)) {
                    budgetTypes.push(6)
                }
            }
        })

        const copiedAllBudgetTypesOptions: Option[] = JSON.parse(JSON.stringify(allBudgetTypesOptions)) 
        const tempOptions: Option[] = []
        budgetTypes.sort((a: number, b: number) => a - b).forEach((budget: number) => {
            const budgetTypeOption = copiedAllBudgetTypesOptions.find(item => item.value === budget)!
            tempOptions.push({...budgetTypeOption})
        })

        this.parameterOptions.budgetTypes = copiedAllBudgetTypesOptions.filter(it => budgetTypes.includes(it.value as number))

        this.reportParameters.budgetType = tempOptions
    }

    public onYearChange() {
        switch (this.reportParameters.periodicity.value) {
            case 'QUARTER':
                this.onYearOrQuarterChange()
                break
            case 'MONTH':
                this.onYearOrMonthChange()
                break
            default:
        }
        
    }

    public onYearOrQuarterChange() {
        this.period = null
        this.reportParameters.regions = []
        this.parameterOptions.regions = []
        const year = this.reportParameters.year
        const quarter = this.reportParameters.quarter?.value as number
        if (!year || !quarter) {
            return
        }
        this.period = getLastDateOfQuarter(year, quarter)
        this.setRegionOptions()
    }

    public onYearOrMonthChange() {
        this.period = null
        this.reportParameters.regions = []
        this.parameterOptions.regions = []
        const year = this.reportParameters.year
        const month = this.reportParameters.month?.value as number
        if (!year || !month) {
            return
        }
        this.period = getLastDateOfMonth(year, month)
        this.setRegionOptions()
    }

    public updateIndicators(newIndicators: Indicator[]) {
        this.indicators = newIndicators
        const indicatorsOrder = this.indicators
            .filter(item => item.active)
            .map(item => item.key)
        const excelParams: JournalCreateRequest = JSON.parse(JSON.stringify(this.excelParameters))
        excelParams.table_data.indicators_order = indicatorsOrder
        this.$emit("set-journal-params", excelParams)
    }

    private async setCategoryOptions() {
        if (['INCOMES', 'MIXED'].includes(this.templateData.classification)) {
            const incomeResponse = await fetch('/api-py/monitoring/reports-constructor/income-options')
            if (!incomeResponse.ok) {
                this.makeToast('Не удалось получить данные для выбора доходов', 'Ошибка', 'danger')
                return
            }
            const incomeData = await incomeResponse.json()
            this.incomeOptions.kat = incomeData.kat
            this.incomeOptions.cls = incomeData.cls
            this.incomeOptions.pcl = incomeData.pcl
            this.incomeOptions.spf = incomeData.spf
        }

        if (['EXPENSES', 'MIXED'].includes(this.templateData.classification)) {
            const expenseResponse = await fetch(`/api-py/monitoring/reports-constructor/expense-options/${this.templateData.reportName}`)
            if (!expenseResponse.ok) {
                this.makeToast('Не удалось получить данные для выбора расходов', 'Ошибка', 'danger')
                return
            }
            const expenseData = await expenseResponse.json()
            this.expenseOptions.gr = expenseData.gr
            this.expenseOptions.pgr = expenseData.pgr
            this.expenseOptions.abp = expenseData.abp
            this.expenseOptions.gu = expenseData.gu
            this.expenseOptions.prg = expenseData.prg
            this.expenseOptions.ppr = expenseData.ppr
            this.expenseOptions.spf = expenseData.spf
        }
    }

    // Промежуточный метод для setRegionOptions
    // поскольку при созданий компонента год и квартал берутся с props templateData 
    private async setRegionOptionsOnCreate() {
        switch (this.templateData.periodicity) {
            case 'MONTH': {
                const year = this.templateData.year
                const month = this.templateData.month
                if (year && month) {
                    this.period = getLastDateOfMonth(year, month)
                    await this.setRegionOptions()
                }
                break
            }
            case 'QUARTER': {
                const year = this.templateData.year
                const quarter = this.templateData.quarter
                if (year && quarter) {
                    this.period = getLastDateOfQuarter(year, quarter)
                    await this.setRegionOptions()
                }
                break
            }
            default:
        }
    }

    private async setRegionOptions() {
        this.regionLoading = true
        try {
            if (!this.period) {
                return
            }
            const endpointUrl = `/api-py/monitoring/reports-constructor/regions/${this.userUiid}/${this.period}`
            const response = await fetch(endpointUrl)
            if (!response.ok) {
                throw new Error('Ошибка загрузки регионов')
            }
            const data = await response.json()
            const regions: Option[] = data.map((item: any) => {
                const temp: Option = {
                    value: item.code,
                    label: `${item.code} - ${item.name_ru}`
                }
                return temp
            })
            this.parameterOptions.regions = regions
            if (regions.length > 0) {
                this.reportParameters.regions = [regions[0]]
                this.onRegionSelect([regions[0]])
            }
        } catch (error) {
            this.makeToast('Не удалось загрузить регионы', 'Ошибка', 'danger')
        } finally {
            this.regionLoading = false
        }
    }

    async setReportParameters() {
        const reportParams = this.templateData

        if (!reportParams) {
            // TODO: handle
        }

        
        const tempBudgetTypes: Option[] = []
        reportParams!.budgetType.forEach(budget => {
            const budgetTypeOption = this.parameterOptions.budgetTypes.find(item => item.value === budget)!
            tempBudgetTypes.push(budgetTypeOption)
        })

        const tempRegions: Option[] = []
        reportParams!.regions.forEach(region => {
            const value = this.parameterOptions.regions.find(item => item.value === region)!
            tempRegions.push(value)
        })

        const temp: ReportParametersWithOption = {
            id: reportParams.id,
            reportName: this.parameterOptions.reportNames.find(item => item.value == reportParams!.reportName) as Option,
            reportType: this.parameterOptions.reportTypes.find(item => item.value == reportParams!.reportType) as Option,
            classification: this.parameterOptions.classifications.find(item => item.value == reportParams!.classification) as Option,
            regions: tempRegions,
            mutuallyRedeeming: this.parameterOptions.mutuallyRedeeming.find(item => item.value == reportParams!.mutuallyRedeeming) as Option,
            dataSource: this.parameterOptions.dataSources.find(item => item.value == reportParams!.dataSource) as Option,
            periodicity: this.parameterOptions.periodicities.find(item => item.value == reportParams!.periodicity) as Option,
            budgetType: tempBudgetTypes,
            measureUnit: this.parameterOptions.measureUnits.find(item => item.value == reportParams!.measureUnit) as Option,
            budgetStructure: this.parameterOptions.budgetStructures.find(item => item.value == reportParams!.budgetStructure) ?? null,
            developType: this.parameterOptions.developTypes.find(item => item.value == reportParams!.developType) ?? null,
            incomeTotal: reportParams!.incomeTotal,
            expenseTotal: reportParams!.expenseTotal,

            year: reportParams!.year ?? null,
            quarter: this.parameterOptions.quarters.find(item => item.value == reportParams!.quarter) ?? null,
            month: this.parameterOptions.months.find(item => item.value == reportParams!.month) ?? null,
            date: reportParams!.date ?? null,

            abpSummary: reportParams!.abpSummary,
            excludeZeroRows: reportParams!.excludeZeroRows,
            separateByRegion: reportParams!.separateByRegion,
            roundUp: reportParams!.roundUp,
            roundUpTo: reportParams!.roundUpTo,

            name: reportParams!.name,
            description: reportParams!.description,
            isShared: reportParams!.isShared
        }

        this.reportParameters = temp

        if (this.templateData.incomes) {
            this.incomes = JSON.parse(JSON.stringify(this.templateData.incomes))
        }
        if (this.templateData.expenses) {
            this.expenses = JSON.parse(JSON.stringify(this.templateData.expenses))
        }

        this.indicators = JSON.parse(JSON.stringify(this.templateData.indicators))
    }

    async onConstructReport() {
        this.loading = true
        try {
            await this.constructReport()
        } catch (error) {
            this.makeToast('Не удалось сформировать отчет', 'Ошибка', 'danger')
        } finally {
            this.loading = false
        }
    }
    async constructReport() {
        const activeIncomes = this.incomes.filter(item => item.active)
        const activeExpenses = this.expenses.filter(item => item.active)

        // #region Validations
        if (['PR2'].includes(this.templateData.reportName)) {
            if (!this.reportParameters.year || !this.reportParameters.quarter) {
                this.makeToast('Необходимо ввести год и квартал', 'Сообщение', 'danger')
                return
            } // TODO: implement validation as in book-dicts
        }
        if (['1-27'].includes(this.templateData.reportName)) {
            if (!this.reportParameters.year || !this.reportParameters.month) {
                this.makeToast('Необходимо ввести год и месяц', 'Сообщение', 'danger')
                return
            } // TODO: implement validation as in book-dicts
        }
        if (this.reportParameters.regions.length === 0) {
            this.makeToast('Необходимо ввести регион', 'Сообщение', 'danger')
            return
        } // TODO: implement validation as in book-dicts
        if (this.reportParameters.mutuallyRedeeming.value !== 'WITH') {
            const isFullHierarchy = activeIncomes.length === 4
            if (!isFullHierarchy) {
                this.makeToast('Для "без/только ВЗК" необходимо выбрать все доходы', 'Сообщение', 'danger')
                return
            }

            this.reportParameters.regions.map(it => it.value as string).forEach(region => {
                const isOblast = ['0101', '0000'].includes(region.substring(2))
                const isRayon = ['01', '00'].includes(region.substring(4))
                if (!isOblast && !isRayon) {
                    this.makeToast('Для "без/только ВЗК" необходимо выбрать областной/районный регион', 'Сообщение', 'danger')
                    return
                }
            })
        }
        let roundUpTo: number | null = null
        if (this.reportParameters.roundUp) {
            // @ts-ignore
            const userInputRoundUpTo = parseInt(this.reportParameters.roundUpTo)
            if (Number.isNaN(userInputRoundUpTo)) { 
                this.makeToast('Необходимо ввести значение округления', 'Сообщение', 'danger')
                return // TODO: re-implement using vue-validation 
            }
            if (userInputRoundUpTo < 0 || userInputRoundUpTo > 4) { 
                this.makeToast('Допустимое округление 0 - 4', 'Сообщение', 'danger')                    
                return
            } // TODO: re-implement using vue-validation
            roundUpTo = this.reportParameters.roundUpTo
        }
        // #endregion

        const incomesOrder = activeIncomes.map(item => item.key)
        const incomeFilters = {}
        activeIncomes.forEach(item => {
            // @ts-ignore
            incomeFilters[item.key] = item.selected
        })
        const expensesOrder = activeExpenses.map(item => item.key)
        const expenseFilters = {}
        activeExpenses.forEach(item => {
            // @ts-ignore
            expenseFilters[item.key] = item.selected
        })
        
        this.incomesToPass = JSON.parse(JSON.stringify(this.incomes))
        this.expensesToPass = JSON.parse(JSON.stringify(this.expenses))

        const indicatorsOrder = this.indicators
            .filter(item => item.active)
            .map(item => item.key)

        switch (this.templateData.reportName) {
            case 'PR2': {
                const parameters = {
                    incomes_order: incomesOrder,
                    income_filters: incomeFilters,
                    year: this.reportParameters.year,
                    quarter: this.reportParameters.quarter!.value,
                    regions: this.reportParameters.regions.map(it => it.value),
                    budget_types: this.reportParameters.budgetType.map(item => item.value),
                    measure_unit: this.reportParameters.measureUnit.value,
                    round_up_to: roundUpTo,
                    mutually_redeeming: this.reportParameters.mutuallyRedeeming.value
                }
                const response = await fetch('/api-py/monitoring/reports-constructor/application-2',
                    {
                        method: 'POST',
                        body: JSON.stringify(parameters)
                    }
                )
                if (!response.ok) {
                    this.makeToast('Не удалось получить данные для отчета', 'Ошибка', 'danger')
                    return
                }
                const tablesData: Application2Response = await response.json()
                const unfulfilledMappedData: Application2TableRow[] = tablesData.unfulfilled.map((item: any, index: number) => {
                    const temp: Application2TableRow = {
                        index: index + 1,
                        kat: item.kat ?? null,
                        cls: item.cls ?? null,
                        pcl: item.pcl ?? null,
                        spf: item.spf ?? null,
                        fullcode: item.fullcode ?? null,
                        nameRu: item.name_ru,
                        plan: item.plan,
                        fact: item.fact,
                        delta1: item.delta1,
                        delta2: item.delta2,
                        reasons: null
                    }
                    return temp
                })
                const overfulfilledMappedData: Application2TableRow[] = tablesData.overfulfilled.map((item: any, index: number) => {
                    const temp: Application2TableRow = {
                        index: index + 1,
                        kat: item.kat ?? null,
                        cls: item.cls ?? null,
                        pcl: item.pcl ?? null,
                        spf: item.spf ?? null,
                        fullcode: item.fullcode ?? null,
                        nameRu: item.name_ru,
                        plan: item.plan,
                        fact: item.fact,
                        delta1: item.delta1,
                        delta2: item.delta2,
                        reasons: null
                    }
                    return temp
                })

                const tempTableData: Application2TableData = {
                    overfulfilledTableData: overfulfilledMappedData,
                    unfulfilledTableData: unfulfilledMappedData,
                    overfulfilledTotal: tablesData.overfulfilled_total,
                    unfulfilledTotal: tablesData.unfulfilled_total
                }
                this.tableData = tempTableData

                const excelParams: JournalCreateRequest = {
                    name: this.reportParameters.name,
                    description: this.reportParameters.name,
                    is_shared: this.reportParameters.isShared,
                    user_id: this.userUiid,
                    username: this.username,
                    settings: this.createSettings(),
                    table_data: {
                        incomes_order: incomesOrder,
                        indicators_order: indicatorsOrder,
                        unfulfilled_table: unfulfilledMappedData,
                        overfulfilled_table: overfulfilledMappedData,
                        unfulfilled_total: tablesData.unfulfilled_total,
                        overfulfilled_total: tablesData.overfulfilled_total,
                    }
                }
                this.excelParameters = excelParams
                this.$emit("set-journal-params", excelParams)
                break
            }
            case '1-27': {
                const parameters = {
                    incomes_order: incomesOrder,
                    income_filters: incomeFilters,
                    expenses_order: expensesOrder,
                    expense_filters: expenseFilters,
                    year: this.reportParameters.year,
                    month: this.reportParameters.month!.value,
                    regions: this.reportParameters.regions.map(it => it.value),
                    budget_types: this.reportParameters.budgetType.map(item => item.value),
                    measure_unit: this.reportParameters.measureUnit.value,
                    round_up_to: roundUpTo,
                    mutually_redeeming: this.reportParameters.mutuallyRedeeming.value,
                    budget_structure: this.reportParameters.budgetStructure!.value,
                    develop_type: this.reportParameters.developType!.value,
                    income_total: this.reportParameters.incomeTotal,
                    expense_total: this.reportParameters.expenseTotal
                    // separate_by_region: this.reportParameters.separateByRegion
                }
                const response = await fetch('/api-py/monitoring/reports-constructor/1-27',
                    {
                        method: 'POST',
                        body: JSON.stringify(parameters)
                    }
                )
                if (!response.ok) {
                    this.makeToast('Не удалось получить данные для отчета', 'Ошибка', 'danger')
                    return
                }
                const responseTableData = await response.json()

                let index = 0
                const mapExpenseRow = (item: any): Report127TableExpenseRow => {
                    const temp: Report127TableExpenseRow = {
                        index: index++,
                        gr: item.gr ?? null,
                        pgr: item.pgr ?? null,
                        abp: item.abp ?? null,
                        prg: item.prg ?? null,
                        ppr: item.ppr ?? null,
                        spf: item.spf ?? null,
                        nameRu: item.name ?? null,
                        utv: item.utv,
                        utch: item.utch,
                        plg: item.plg,
                        plgp: item.plgp,
                        plgo: item.plgo,
                        obz: item.obz,
                        nobz: item.nobz,
                        sumrg: item.sumrg,
                        percentage1: item.percentage1,
                        percentage2: item.percentage2
                    }
                    return temp
                }
                const mapIncomeRow = (item: any): Report127TableIncomeRow => {
                    const temp: Report127TableIncomeRow = {
                        index: index++,
                        kat: item.kat ?? null,
                        cls: item.cls ?? null,
                        pcl: item.pcl ?? null,
                        spf: item.spf ?? null,
                        nameRu: item.name ?? null,
                        utv: item.utv,
                        utch: item.utch,
                        plg: item.plg,
                        plgp: item.plgp,
                        plgo: item.plgo,
                        obz: item.obz,
                        nobz: item.nobz,
                        sumrg: item.sumrg,
                        percentage1: item.percentage1,
                        percentage2: item.percentage2
                    }
                    return temp
                }
                const mapBaseRow = (item: any): Report127TableBaseRow => {
                    return {
                        index: index++,
                        nameRu: item.name ?? null,
                        utv: item.utv,
                        utch: item.utch,
                        plg: item.plg,
                        plgp: item.plgp,
                        plgo: item.plgo,
                        obz: item.obz,
                        nobz: item.nobz,
                        sumrg: item.sumrg,
                        percentage1: item.percentage1,
                        percentage2: item.percentage2
                    }
                }

                let tempTableData: Report127TableData | null = null

                if (this.reportParameters.budgetStructure!.value === 'WITH') {
                    const generalIncomeTotal: Report127TableBaseRow = mapBaseRow(responseTableData.general_income_total)
                    const generalExpenseTotal: Report127TableBaseRow = mapBaseRow(responseTableData.general_expense_total)
                    const generalIncome: Report127TableIncomeRow[] = responseTableData.general_income.map(mapIncomeRow)
                    const generalExpense: Report127TableExpenseRow[] = responseTableData.general_expense.map(mapExpenseRow)
                    const budgetLoanDelta: Report127TableBaseRow = mapBaseRow(responseTableData.budget_loan_delta)
                    const budgetLoanExpenseTotal: Report127TableBaseRow = mapBaseRow(responseTableData.budget_loan_expense_total)
                    const budgetLoanExpense: Report127TableExpenseRow[] = responseTableData.budget_loan_expense.map(mapExpenseRow)
                    const budgetLoanIncomeTotal: Report127TableBaseRow = mapBaseRow(responseTableData.budget_loan_income_total)
                    const budgetLoanIncome: Report127TableIncomeRow[] = responseTableData.budget_loan_income.map(mapIncomeRow)
                    const financialActivesDelta: Report127TableBaseRow = mapBaseRow(responseTableData.financial_actives_delta)
                    const financialActivesExpenseTotal: Report127TableBaseRow = mapBaseRow(responseTableData.financial_actives_expense_total)
                    const financialActivesExpense: Report127TableExpenseRow[] = responseTableData.financial_actives_expense.map(mapExpenseRow)
                    const financialActivesIncomeTotal: Report127TableBaseRow = mapBaseRow(responseTableData.financial_actives_income_total)
                    const financialActivesIncome: Report127TableIncomeRow[] = responseTableData.financial_actives_income.map(mapIncomeRow)
                    const budgetDeficit: Report127TableBaseRow = mapBaseRow(responseTableData.budget_deficit)
                    const nonOilBudgetDeficit: Report127TableBaseRow = mapBaseRow(responseTableData.non_oil_budget_deficit)
                    const financeDeficitBudgetDelta: Report127TableBaseRow = mapBaseRow(responseTableData.finance_deficit_budget_delta)
                    const financeDeficitBudgetIncome7Total: Report127TableBaseRow = mapBaseRow(responseTableData.finance_deficit_budget_income_7_total)
                    const financeDeficitBudgetIncome7: Report127TableIncomeRow[] = responseTableData.finance_deficit_budget_income_7.map(mapIncomeRow)
                    const financeDeficitBudgetExpenseTotal: Report127TableBaseRow = mapBaseRow(responseTableData.finance_deficit_budget_expense_total)
                    const financeDeficitBudgetExpense: Report127TableExpenseRow[] = responseTableData.finance_deficit_budget_expense.map(mapExpenseRow)
                    const usedBudgetLeft: Report127TableBaseRow = mapBaseRow(responseTableData.used_budget_left)
                    const accSldBeg: Report127TableBaseRow = mapBaseRow(responseTableData.acc_sld_beg)
                    const accSldEnd: Report127TableBaseRow = mapBaseRow(responseTableData.acc_sld_end)

                    tempTableData = {
                        generalIncomeTotal: generalIncomeTotal,
                        generalIncome: generalIncome,

                        generalExpenseTotal: generalExpenseTotal,
                        generalExpense: generalExpense,

                        budgetLoanDelta: budgetLoanDelta,
                        budgetLoanExpenseTotal: budgetLoanExpenseTotal,
                        budgetLoanExpense: budgetLoanExpense,
                        budgetLoanIncomeTotal: budgetLoanIncomeTotal,
                        budgetLoanIncome: budgetLoanIncome,

                        financialActivesDelta: financialActivesDelta,
                        financialActivesExpenseTotal: financialActivesExpenseTotal,
                        financialActivesExpense: financialActivesExpense,
                        financialActivesIncomeTotal: financialActivesIncomeTotal,
                        financialActivesIncome: financialActivesIncome,

                        budgetDeficit: budgetDeficit,
                        nonOilBudgetDeficit: nonOilBudgetDeficit,
                        financeDeficitBudgetDelta: financeDeficitBudgetDelta,
                        financeDeficitBudgetIncome7Total: financeDeficitBudgetIncome7Total,
                        financeDeficitBudgetIncome7: financeDeficitBudgetIncome7,
                        financeDeficitBudgetExpenseTotal: financeDeficitBudgetExpenseTotal,
                        financeDeficitBudgetExpense: financeDeficitBudgetExpense,
                        usedBudgetLeft: usedBudgetLeft,

                        accSldBeg: accSldBeg,
                        accSldEnd: accSldEnd
                    }
                }
                else if (this.reportParameters.budgetStructure!.value === 'WITHOUT') {
                    tempTableData = {}
                    if (responseTableData.income_total) {
                        tempTableData.incomeTotal = mapBaseRow(responseTableData.income_total)
                    }
                    if (responseTableData.general_income_total) {
                        tempTableData.generalIncomeTotal = mapBaseRow(responseTableData.general_income_total)
                        tempTableData.generalIncome = responseTableData.general_income.map(mapIncomeRow)
                    }
                    if (responseTableData.expense_total) {
                        tempTableData.expenseTotal = mapBaseRow(responseTableData.expense_total)
                    }
                    if (responseTableData.general_expense_total) {
                        tempTableData.generalExpenseTotal = mapBaseRow(responseTableData.general_expense_total)
                        tempTableData.generalExpense = responseTableData.general_expense.map(mapExpenseRow)
                    }
                }
                else {
                    throw new Error('Неподдерживаемая структура бюджета (budget_structure)')
                }
                
                this.tableData = tempTableData

                const excelTableData: Report127JournalTableData = {
                    incomes_order: incomesOrder,
                    expenses_order: expensesOrder,
                    indicators_order: indicatorsOrder,
                    table_data: responseTableData,
                    date: this.period!,
                    region_labels: this.reportParameters.regions.map(it => it.label),
                    // @ts-ignore
                    mutually_redeeming: this.reportParameters.mutuallyRedeeming.value,
                    budget_types: this.reportParameters.budgetType.map(it => it.value as number),
                    measure_unit: this.reportParameters.measureUnit.label,
                    round_up: roundUpTo
                }

                const excelParams: JournalCreateRequest = {
                    name: this.reportParameters.name,
                    description: this.reportParameters.name,
                    is_shared: this.reportParameters.isShared,
                    user_id: this.userUiid,
                    username: this.username,
                    settings: this.createSettings(),
                    table_data: excelTableData
                }
                this.excelParameters = excelParams

                this.$emit("set-journal-params", excelParams)
                break
            }
            default:
        }
        this.makeToast('Сформировано', 'Сообщение', 'success')
        this.tableKey = this.tableKey + 1
    }

    public changeParametersToDefault() {
        this.tableData = null
        // TODO: implement
    }

    public toggleSaveAsNewModal(value: boolean | null) {
        if (typeof value === 'boolean') {
            this.saveAsNewModal = value    
        } else {
            this.saveAsNewModal = !this.saveAsNewModal
        }
    }

    public async onSaveAsNew() {
        const reservedNames = ['приложение 2', 'отчет 1-27', 'отчёт 1-27']
        if (reservedNames.includes(this.reportParameters.name.toLowerCase())) {
            this.makeToast('Необходимо изменить название', 'Сообщение', 'danger')
            return
        }
        
        const settings: ReportParameters = this.createSettings()

        const payload = {
            name: this.reportParameters.name,
            description: this.reportParameters.description,
            is_shared: this.reportParameters.isShared,
            settings: settings,
            user_id: this.userUiid,
        }
        const response = await fetch('/api-py/monitoring/reports-constructor/templates', {
                method: 'POST',
                body: JSON.stringify(payload)
            }
        )
        if (!response.ok) {
            this.makeToast('Не удалось сохранить шаблон', 'Ошибка', 'danger')
            return
        }
        const addedItem = await response.json()
        this.reportParameters.id = addedItem.id
        this.makeToast('Сохранено', 'Сообщение', 'success')
        this.toggleSaveAsNewModal(false)
        this.templateName = this.reportParameters.name
    }

    public async onUpdate() {
        const settings: ReportParameters = this.createSettings()
        const payload = {
            id: this.reportParameters.id,
            name: this.reportParameters.name,
            description: this.reportParameters.description,
            is_shared: this.reportParameters.isShared,
            settings: settings,
            user_id: this.userUiid,
        }
        const response = await fetch('/api-py/monitoring/reports-constructor/templates', {
                method: 'PUT',
                body: JSON.stringify(payload)
            }
        )
        if (!response.ok) {
            this.makeToast('Не удалось сохранить шаблон', 'Ошибка', 'danger')
            return
        }
        this.makeToast('Изменения сохранены', 'Сообщение', 'success')
        this.toggleSaveAsNewModal(false)
    }

    // TODO: Use toast from common/utils
    private makeToast(message: string, title: string, variant: 'success' | 'danger' | 'info') {
        this.$bvToast.toast(message, {
            title: title,
            variant: variant,
            solid: true,
        })
    }

    private createSettings(): ReportParameters {
        const settings: ReportParameters = {
            // @ts-ignore
            reportName: this.reportParameters.reportName.value,
            // @ts-ignore
            reportType: this.reportParameters.reportType.value,
            // @ts-ignore
            classification: this.reportParameters.classification.value,

            regions: this.reportParameters.regions.map(it => it.value as string),
            // @ts-ignore
            mutuallyRedeeming: this.reportParameters.mutuallyRedeeming.value,
            // @ts-ignore
            dataSource: this.reportParameters.dataSource.value,
            // @ts-ignore
            periodicity: this.reportParameters.periodicity.value,
            // @ts-ignore
            budgetStructure: this.reportParameters.budgetStructure?.value ?? null,
            // @ts-ignore
            developType: this.reportParameters.developType.value ?? null,
            
            budgetType: this.reportParameters.budgetType.map(item => item.value as number),
            // @ts-ignore
            measureUnit: this.reportParameters.measureUnit.value,

            year: this.reportParameters.year ?? null,
            // @ts-ignore
            quarter: this.reportParameters.quarter?.value ?? null,
            // @ts-ignore
            month: this.reportParameters.month?.value ?? null,
            date: this.reportParameters.date ?? null,

            abpSummary: this.reportParameters.abpSummary,
            excludeZeroRows: this.reportParameters.excludeZeroRows,
            separateByRegion: this.reportParameters.separateByRegion,
            roundUp: this.reportParameters.roundUp,
            roundUpTo: this.reportParameters.roundUpTo,

            incomeTotal: this.reportParameters.incomeTotal,
            expenseTotal: this.reportParameters.incomeTotal,

            incomes: JSON.parse(JSON.stringify(this.incomes)),
            expenses: JSON.parse(JSON.stringify(this.expenses)),

            indicators: JSON.parse(JSON.stringify(this.indicators))
        }
        return settings
    }
    // #endregion
}
