<template>
    <div>
        <div class="table-container">
            <!-- хлебные крошки-->
            <div class="filter-breadcrumb">
                <span class="item-breadcrumb" v-if="reg !== null && reg_name !== null" >{{ reg }} - {{ reg_name }}</span>
                <span class="item-breadcrumb" v-if="year !== null" >{{'Год'}} - {{ year }}</span>
                <span class="item-breadcrumb" v-if="qvartal !== null" >{{ qvartal}}</span>
            </div>
        </div>
        <div class="table-container">
            <b-table
                :fields="tableFields"
                :items="inAnalysis"
                responsive="true"
                bordered
                head-variant="light"
                no-border-collapse
                sticky-header="true"
            >
                <template #top-row="data">
                    <td v-for="(field, c) in data.fields.filter(v => ((v.thClass !== 'dhNone') && (v.label !== '')))"
                        :key="c + field" class="td-numbering">
                        <div class="text-center">
                            {{ c + 1 }}
                        </div>
                    </td>
                </template>
                <template #cell(reasons)="data">
                    <b-form-group>
                        <div>
                            <b-form-textarea
                                rows="3"
                                max-rows="5"
                                class="w-250px"
                                v-model="data.item.reasons"
                                :disabled="access_level === 1"
                                @change="onReasonChange(data.item)">
                            </b-form-textarea>
                        </div>
                    </b-form-group>
                </template>
                <template #cell()="data">
                    <div class="text-left">{{ $n(data.value) }}</div>
                </template>
                <template #cell(num)="data">
                    {{ data.index + 1 }}
                </template>
                <template #cell(name_ru)="data">
                    <div class="text-left">
                        {{ data.item.name_ru }}
                    </div>
                </template>
                <template #bottom-row="data">
                    <td></td>
                    <td class="text-left" colspan="2">ИТОГО</td>
                    <td class="text-left">{{ isNaN(total_op) ? 0 : $n(total_op) }}</td>
                    <td class="text-left">{{ isNaN(total_of) ? 0 : $n(total_of) }}</td>
                    <td class="text-left">{{ isNaN(total_o1) ? 0 : $n(total_o1) }}</td>
                    <td class="text-left">{{ '' }}</td>
                    <td></td>
                </template>
            </b-table>
        </div>
        <div class="actions-tab in-tab">
            <b-dropdown variant="link" right toggle-class="text-decoration-none" no-caret>
                <template #button-content >
                    <div class="btn-download">
                        <div><span class="left">
                            <i class="icon icon-download"></i> Скачать</span>
                            <i class="icon icon-keyboard"></i>
                        </div>
                    </div>
                </template>
                <b-dropdown-item @click="downloadRep(curReport)">{{repName}}</b-dropdown-item>
            </b-dropdown>
        </div>
        <div>
            <loading
                :active="loading"
                is-full-screen
                spinner="bar-fade-scale"
                color="#6495ED"
            />
        </div>
    </div>
</template>
<script>
import store from "../../../../services/store";
import {Ax} from '@/utils';
import moment from 'moment';
import VueElementLoading from "vue-element-loading";
function date_for_qvartal(year, qvartal) {
    const result = {};

    if (qvartal === 'Ⅰ квартал') {
        result.date_from = `${year}-01-01`;
        result.date_to = `${year}-03-31`;
    } else if (qvartal === 'Ⅱ квартал') {
        result.date_from = `${year}-04-01`;
        result.date_to = `${year}-06-30`;
    } else if (qvartal === 'Ⅲ квартал') {
        result.date_from = `${year}-07-01`;
        result.date_to = `${year}-09-30`;
    } else if (qvartal === 'Ⅳ квартал') {
        result.date_from = `${year}-10-01`;
        result.date_to = `${year}-12-31`;
    }

    return result;
}

export default {
    name: "application-form-3",
    components: {'loading': VueElementLoading},
    data() {
        return {
            obl: null,
            access_level: 1,
            listReg: [],
            curParams: {},
            reg: null,
            reg_name: null,
            qvartal: null,
            year: null,
            dateFrom: null,
            modalForm: false,
            firstDateFrom: `${new Date().getFullYear()}-${new Date().getMonth()}` + '-01',
            firstDateTo: `${new Date().getFullYear()}-${new Date().getMonth()}-${new Date(new Date().getFullYear(), new Date().getMonth(), 0).getDate()}`,
            curDate: null,
            dateTo: null,
            isLoad: false,
            isSave: false,
            isOpen: false,
            isNew: false,
            report_id: 3,
            repCode: 'PR3',
            repName: 'Приложение_3',
            loading: false,
             tableFields: [
                {
                    key: 'num',
                    label: '№'
                },
                {
                    key: 'dspk',
                    label: 'Код БК'
                },
                {
                    key: 'name_ru',
                    label: 'Наименование поступлений'
                },
                {
                    key: 'plan',
                    label: 'Факт поступления отчетного периода текущего года'
                },
                {
                    key: 'fact',
                    label: 'Факт поступления аналогичного периода прошлого года'
                },
                {
                    key: 'delta1',
                    label: 'Отклонение (гр.4-гр.5)'
                },
                {
                    key: 'delta2',
                    label: 'Темп роста, снижения (гр.4/гр.5*100),%'
                },
                {
                    key: 'reasons',
                    label: 'Причины'
                }
            ],
            listReports: [],
            inAnalysis: [],
            curTab: 0,
            changeRow: false,
            curReport: [],
            curRegion: 0,
            newReport: { id: 0, name_ru: '', name_kk: '', name_en: '' },
            modalDataIsSavable: null
        };
    },

    async mounted() {
        await this.getAccessLevel();
    },
    created() {
        this.$on('loadApplication3', (reg, reg_name, period, date_to, qvartal, year, obl) => {
            this.reportOpen(reg, period, date_to, qvartal, year, obl);
            this.reg = reg;
            this.year = year;
            this.qvartal = qvartal;
            this.reg_name = reg_name;
        });
    },
    computed: {
        labelText() {
            return this.curReport.name_ru;
        },
        total_of() {
            let sumf = 0;
            for (const row of this.curRep()) {
                sumf += parseFloat(row.fact);
            }
            return sumf;
        },
        total_op() {
            let sump = 0;
            for (const row of this.curRep()) {
                sump += parseFloat(row.plan);
            }
            return sump;
        },
        total_o1() {
            let sum1 = 0;
            for (const row of this.curRep()) {
                sum1 += parseFloat(row.delta1);
            }
            return sum1;
        },
        buttonVar() {
            return this.isSave ? "success" : "secondary";
        }
    },
    methods: {
        async getAccessLevel() {
            try {
                const response = await fetch(`/api-py/user-modules/${this.$store.getters.user_uuid}/005.003.006`);

                const modules_access = await response.json();
                if (modules_access.length > 1) {
                    this.makeToast("danger", "Ошибка", "У пользователя несколько записей")
                }
                if (modules_access.length === 0) {
                    this.access_level = 1;
                } else {
                    for (const ma of modules_access) {
                        this.access_level = ma.access_level;
                    }
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса load_user_access_level', error.toString());
            }
        },
        labelTxt(item) {
            return "с " + moment(item.date_from).format('DD.MM.YYYY') + " по " + moment(item.date_to).format('DD.MM.YYYY');
        },
        lblTxt(item) {
            return moment(item.repdate).format('DD.MM.YYYY HH:mm');
        },
        nameTxt(item) {
            if (this.$i18n.locale === 'ru') {
                return item.name_ru;
            } else if (this.$i18n.locale === 'kk') {
                return item.name_kk;
            } else if (this.$i18n.locale === 'en') {
                return item.name_en;
            }
        },
        setNameLang(obj, codeName) {
            let txt = obj['name_' + this.$i18n.locale];
            if (txt === undefined) {
                txt = obj.name_ru;
            }
            if (codeName !== undefined && codeName !== null && obj[codeName] !== undefined) {
                txt = obj[codeName] + ' - ' + txt;
            }
            const el = Object.assign({}, obj);
            el.name = txt;
            return el;
        },
        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        },
        curRep() {
            if (this.curTab) {
                return this.overReport
            }
            else {
                return this.inAnalysis
            }
        },
        paramClear() {
            this.firstDateFrom = null;
            this.firstDateTo = null;
            this.reg = null;
        },
        async getReportList() {
            this.listReports = []
            const params = { repCode: this.repCode }
            const response = await fetch(`/api-py/get-report-data-2/` + JSON.stringify(params));
            this.listReports = await response.json();
        },
        async reportOpen(reg, period, date_to, qvartal, year, obl) {
            this.loading = true;
            this.curParams = {
                region: reg,
                period: period,
                date_to: date_to,
                qvartal: qvartal,
                year: year,
                report_id: this.report_id,
                form: this.repCode,
                obl: obl
            };
            Ax(
                {
                    url: `/api-py/get-fulldata-3/` + JSON.stringify(this.curParams),
                    method: 'GET'
                },
                (data) => {
                    this.inAnalysis = data.inAnalysis;
                    if (this.inAnalysis.length === 0){
                        this.makeToast('info', 'Внимание', 'Данных по таким параметрам нет.');
                    }
                    this.loading = false;
                },
                (error) => {
                    this.makeToast('danger', 'Ошибка запроса', error.toString());
                    this.loading = false;
                }
            )
        },
        toOpen() {
            this.isOpen = !this.isOpen;
            this.$emit('repOpen', this.isOpen);
        },
        repSave() {
            const values = [];
            this.curDate = `${new Date()}`;
            const qvartal_date = date_for_qvartal(this.year, this.qvartal);
            for (const row of this.inAnalysis) {
                const item = Object.assign({}, row);
                this.$set(item, 'report_id', this.report_id);
                this.$set(item, 'repdate', moment(this.curDate).format('YYYY-MM-DD HH:mm'));
                this.$set(item, 'region', this.reg);
                this.$set(item, 'date_from', qvartal_date.date_from);
                this.$set(item, 'date_to', qvartal_date.date_to);
                this.$set(item, 'form', this.repCode);
                values.push(item);
            }
            if (values.length > 0) {
                this.$bvModal.msgBoxConfirm(
                    'Вы действительно хотите сохранить данные?',
                    {
                        title: 'Подтверждение',
                        size: 'lg',
                        buttonSize: 'sm',
                        okVariant: 'success',
                        okTitle: 'Да',
                        cancelTitle: 'Отмена',
                        footerClass: 'p-2',
                        hideHeaderClose: false,
                        centered: true
                    })
                    .then(value => {
                        if (value) {
                            this.save(values, 1);
                        }
                    })
                    .catch(error => {
                        this.makeToast('danger', 'Ошибка сохранения', error.toString());
                    });
            }
        },
        async save(values) {
            this.isLoad = true;
            try {
                const response = await fetch('/api-py/save-pr2/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(values)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    this.makeToast('success', 'Сообщение', 'Данные сохранены');
                    this.$on('loadApplication3', (reg, reg_name, period, date_to, qvartal, year, obl) => {
                        this.reportOpen(reg, period, date_to, qvartal, year, obl);
                    });
                    this.isNew = false;
                    this.isSave = false;
                    this.disable = false;
                } else {
                    throw 'Ошибка сохранения данных. Возможно не все обязательные поля заполнены';
                }
            } catch (e) {
                this.makeToast('danger', 'Предупреждение', e.toString());
            } finally {
                this.isLoad = false;
            }
        }, // сохранение данных
        onReasonChange(item) {
            this.$set(item, 'onSave', true);
            this.$emit('reasonChanged');
        },
        downloadRep() {
            try {
                this.makeToast('info', 'Внимание!', 'Скоро начнется скачивание. Пожалуйста ожидайте.');
                this.isLoad = true
                Ax(
                    {
                        url: '/api-py/fulfill_report3/' + JSON.stringify(this.curParams),
                        method: 'POST',
                        responseType: 'blob'
                    },
                    (data) => {
                        const url = window.URL.createObjectURL(new Blob([data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', this.repName + '.xls');
                        document.body.appendChild(link);
                        link.click();
                    },
                    (error) => {
                        this.makeToast('danger', 'Ошибка запроса downloadRep()', error.toString());
                    }
                );
            } catch (error) {
                console.log(error)
                this.makeToast('danger', 'Предупреждение', 'Необходимо заполнить все обязательные фильтры!');
            } finally {
                this.isLoad = false
            }
        },
        keyPress: function (event, pattern) {
            const regex = new RegExp(pattern);
            const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }, // вводит по заданному паттерну
        keyup13: function (event) {
            event.preventDefault();
            // Isolate the node that we're after
            const currentNode = event.target;
            // find all tab-able elements
            const allElements = document.querySelectorAll('input'); // area, object, select, [contenteditable]
            // Find the current tab index.
            const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el));
            // select/focus the following element
            const targetIndex = (currentIndex + 1) % allElements.length;
            if (targetIndex < allElements.length) {
                allElements[targetIndex].select();
            }
        }, // enter работает как tab
        onChangeRecord(value) {
        this.$nextTick(() => {
            this.$set(this, 'isSave', value.length > 0);
        });
}

}
}
</script>

<style scoped>
.td-numbering{
    padding: 3px 10px!important;
    text-align: center;
    font-size: 0.7rem !important;
    font-weight: 400!important;
    color: #6087A0 !important;
    background-color: #F7F9FC;
}
</style>