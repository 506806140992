<template>
    <div>
        <div class="table-container">
            <table
                class="table b-table table-bordered b-table-no-border-collapse sticky-header"
            >
                <thead class="thead-light" style="position: sticky; top: 40px">
                    <tr>
                        <th v-for="header of tableFields" :key="header.key">
                            <template v-if="header.key === 'num'">
                                <b-button @click="openAll()">
                                    <i
                                        class="icon icon-chevron-circle icon-rotate-180"
                                        v-if="open"
                                    ></i>
                                    <i
                                        class="icon icon-chevron-circle"
                                        v-if="!open"
                                    ></i>
                                </b-button>
                            </template>
                            <template v-else>
                                {{ header.label }}
                            </template>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td
                            v-for="(header, index) of tableFields"
                            :key="'header-' + header.key"
                            class="td-numbering"
                        >
                            <div class="text-center w-100px" v-if="index !== 0">
                                {{ index }}
                            </div>
                        </td>
                    </tr>
                    <tr
                        v-for="(item, index) of unReport"
                        :key="`${index}_${item.name}`"
                    >
                        <template v-if="item.visible">
                            <td
                                v-for="header of tableFields"
                                :key="'body-' + header.key"
                                style="
                                    padding: 0.75rem;
                                    font-size: 0.7777777778rem;
                                    font-weight: 500;
                                    line-height: 1.3;
                                    color: #0d1a2e !important;
                                    text-transform: none;
                                "
                                :style="
                                    item.region.endsWith('00')
                                        ? 'background-color: rgba(176, 224, 255, .5)!important;'
                                        : item.dataType === 2
                                        ? 'background-color: #d6d8db !important;'
                                        : ''
                                "
                            >
                                <template v-if="header.key === 'num'">
                                    <b-button
                                        v-if="item.region.endsWith('00')"
                                        @click="openChilds(item)"
                                        v-model="item.open"
                                    >
                                        <i
                                            class="icon icon-chevron-circle icon-rotate-180"
                                            v-if="item.open"
                                        ></i>
                                        <i
                                            class="icon icon-chevron-circle"
                                            v-if="!item.open"
                                        ></i>
                                    </b-button>
                                </template>
                                <template v-if="header.key === 'name'">
                                    <div v-if="item.region.slice(2, 6) !== '0101'" class="text-left w-200px">
                                        {{ item.region }} - {{ item.name }}
                                    </div>
                                    <div v-else class="text-left w-200px">
                                        {{ item.name }}
                                    </div>
                                </template>
                                <template v-else-if="header.key === 'outcomes'">
                                    <div>
                                        {{ $n(item.outcomes) }}
                                    </div>
                                </template>
                                <template
                                    v-else-if="header.key === 'func_app'"
                                >
                                    <div>
                                        {{
                                            item["func_app"]
                                                ? $n(item["func_app"])
                                                : 0
                                        }}
                                    </div>
                                </template>
                                <template
                                    v-else-if="header.key === 'improvement'"
                                >
                                    <div>
                                        {{
                                            item["improvement"]
                                                ? $n(item["improvement"])
                                                : 0
                                        }}
                                    </div>
                                </template>
                                <template
                                    v-else-if="header.key === 'construction'"
                                >
                                    <div>
                                        {{
                                            item["construction"]
                                                ? $n(item["construction"])
                                                : 0
                                        }}
                                    </div>
                                </template>
                                <template
                                    v-else-if="header.key === 'water'"
                                >
                                    <div>
                                        {{
                                            item["water"]
                                                ? $n(item["water"])
                                                : 0
                                        }}
                                    </div>
                                </template>
                                <template
                                    v-else-if="header.key === 'light'"
                                >
                                    <div>
                                        {{
                                            item["light"]
                                                ? $n(item["light"])
                                                : 0
                                        }}
                                    </div>
                                </template>
                                <template
                                    v-else-if="header.key === 'social'"
                                >
                                    <div>
                                        {{
                                            item["social"]
                                                ? $n(item["social"])
                                                : 0
                                        }}
                                    </div>
                                </template>
                                <template
                                    v-else-if="header.key === 'housing'"
                                >
                                    <div>
                                        {{
                                            item["housing"]
                                                ? $n(item["housing"])
                                                : 0
                                        }}
                                    </div>
                                </template>
                                <template
                                    v-else-if="header.key === 'sanitar'"
                                >
                                    <div>
                                        {{
                                            item["sanitar"]
                                                ? $n(item["sanitar"])
                                                : 0
                                        }}
                                    </div>
                                </template>
                                <template
                                    v-else-if="header.key === 'burial'"
                                >
                                    <div>
                                        {{
                                            item["burial"]
                                                ? $n(item["burial"])
                                                : 0
                                        }}
                                    </div>
                                </template>
                                <template
                                    v-else-if="header.key === 'initiat'"
                                >
                                    <div>
                                        {{
                                            item["initiat"]
                                                ? $n(item["initiat"])
                                                : 0
                                        }}
                                    </div>
                                </template>
                                <template
                                    v-else-if="header.key === 'culture'"
                                >
                                    <div>
                                        {{
                                            item["culture"]
                                                ? $n(item["culture"])
                                                : 0
                                        }}
                                    </div>
                                </template>
                                <template
                                    v-else-if="header.key === 'sport'"
                                >
                                    <div>
                                        {{
                                            item["sport"]
                                                ? $n(item["sport"])
                                                : 0
                                        }}
                                    </div>
                                </template>
                                <template
                                    v-else-if="header.key === 'snp'"
                                >
                                    <div>
                                        {{
                                            item["snp"]
                                                ? $n(item["snp"])
                                                : 0
                                        }}
                                    </div>
                                </template>
                                <template
                                    v-else-if="header.key === 'transfert'"
                                >
                                    <div>
                                        {{
                                            item["transfert"]
                                                ? $n(item["transfert"])
                                                : 0
                                        }}
                                    </div>
                                </template>
                                <template
                                    v-else-if="header.key === 'other'"
                                >
                                    <div>
                                        {{
                                            item["other"]
                                                ? $n(item["other"])
                                                : 0
                                        }}
                                    </div>
                                </template>
                            </td>
                        </template>
                    </tr>
                </tbody>
            </table>
        </div>
        <div>
            <loading
                :active="loading"
                is-full-screen
                spinner="bar-fade-scale"
                color="#6495ED"
            />
        </div>
    </div>
</template>
<script>
import { Ax } from '@/utils';
import moment from 'moment';
import VueElementLoading from "vue-element-loading";
import { type } from '@amcharts/amcharts4/core';

export default {
    name: "application-form-budget-msu-outcome",
    components: { 'loading': VueElementLoading },
    props: {
        params: {}
    },
    data() {
        return {
            sheet: null,
            obl: null,
            access_level: 1,
            listReg: [],
            curParams: {},
            reg: null,
            reg_name: null,
            qvartal: null,
            year: null,
            month: null,
            curDate: null,
            dateTo: null,
            repCode: 'budget-msu',
            repName: 'Сводная информация по бюджетам МСУ',
            loading: false,
            tableFields: [
                {
                    key: 'num',
                    label: '№'
                },
                {
                    key: 'name',
                    label: 'Райгород'
                },
                {
                    key: 'outcomes',
                    label: 'Затраты'
                },
                {
                    key: 'func_app',
                    label: 'Функционирование аппаратов акимов (с учетом капитальных расходов и капитальных расходов подведомственных организаций)'
                },
                {
                    key: 'improvement',
                    label: 'Благоустройство и озеленение населенных пунктов'
                },
                {
                    key: 'construction',
                    label: 'Строительство, реконструкция, ремонт и содержание автодорог'
                },
                {
                    key: 'water',
                    label: 'Организация водоснабжения населенных пунктов'
                },
                {
                    key: 'light',
                    label: 'Освещение улиц в населенных пунктах'
                },
                {
                    key: 'social',
                    label: 'Оказание социальной помощи нуждающимся гражданам на дому'
                },
                {
                    key: 'housing',
                    label: 'Организация сохранения государственного жилищного фонда'
                },
                {
                    key: 'sanitar',
                    label: 'Обеспечение санитарии населенных пунктов'
                },
                {
                    key: 'burial',
                    label: 'Содержание мест захоронений и погребение безродных'
                },
                {
                    key: 'initiat',
                    label: 'Расходы на новые инициативы '
                },
                {
                    key: 'culture',
                    label: 'Поддержка культурно-досуговой работы на местном уровне'
                },
                {
                    key: 'sport',
                    label: 'Проведение физкультурно-оздоровительных и спортивных мероприятий'
                },
                {
                    key: 'snp',
                    label: 'Реализация мероприятий для решения обустройства СНП в рамках государственных и правительственных программ'
                },
                {
                    key: 'transfert',
                    label: 'Возврат не использованных (недоиспользованных) целевых трансфертов'
                },
                {
                    key: 'other',
                    label: 'Прочие затраты'
                }
            ],
            unReport: [],
            curTab: 0,
            open: true
        };
    },

    async mounted() {
        await this.getAccessLevel();
        this.$watch('params', () => {
            this.reportOpen()
        })
    },
    created() {
        this.$on('budgetMsu', (reg, reg_name, period, date_to, qvartal, year, obl, uuid, sheet, month) => {
            this.sheet = sheet;
            this.reportOpen();
            this.reg = this.params.reg;
            this.year = this.params.year;
            this.qvartal = qvartal;
            this.reg_name = this.params.reg_name;
            this.dateTo = moment(date_to).format('YYYY-MM-DD');
            this.sheet = sheet;
            this.month = this.params.month;
        });
    },
    methods: {
        async getAccessLevel() {
            try {
                const response = await fetch(`/api-py/user-modules/${this.$store.getters.user_uuid}/005.003.006`);

                const modules_access = await response.json();
                if (modules_access.length > 1) {
                    this.makeToast("danger", "Ошибка", "У пользователя несколько записей")
                }
                if (modules_access.length === 0) {
                    this.access_level = 1;
                } else {
                    for (const ma of modules_access) {
                        this.access_level = ma.access_level;
                    }
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса load_user_access_level', error.toString());
            }
        },
        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        },
        async reportOpen() {
            this.loading = true;
            this.unReport = [];
            this.curParams = {
                region: this.params.reg,
                period: this.params.period,
                date_to: moment(this.params.date_to).format('YYYY-MM-DD'),
                qvartal: this.params.qvartal,
                year: this.params.year,
                report: false,
                form: this.repCode,
                obl: this.params.obl,
                uuid: this.params.uuid,
                sheet: this.params.sheet.code,
                update: false
            };
            Ax(
                {
                    url: `/api-py/get-fulldata-budget-msu/` + JSON.stringify(this.curParams),
                    method: 'GET'
                },
                (data) => {
                    if (data.unReport === 0) {
                        this.makeToast('info', 'Внимание', 'Данных по таким параметрам нет.');
                    } else {
                        const total = {
                            name: 'Всего',
                            region: this.curParams.obl + '0101',
                            visible: true
                        };
                        for (const d of data.unReport.sort((a, b) => a.sort_name > b.sort_name)) {
                            const i = {
                                name: d.name,
                                region: d.code
                            }
                            const region_list = []
                            for (const child of d.childs.sort((a, b) => a.sort_name > b.sort_name)) {
                                const attr = child.code;
                                if (i[attr]) {
                                    i[attr] += child.value;
                                } else {
                                    i[attr] = child.value;
                                }
                                const c = {
                                    region: child.region,
                                    name: child.name
                                }
                                if (!region_list.some(element => element.region === c.region)) {
                                    region_list.push(c);
                                }
                            }
                            const func_app_keys = ['1', '22', '32']
                            let func_app = 0;
                            for (const key of func_app_keys) {
                                if (i[key]) {
                                    func_app += i[key];
                                }
                            }
                            i.func_app = func_app;
                            const improvement_keys = ['11']
                            let improvement = 0;
                            for (const key of improvement_keys) {
                                if (i[key]) {
                                    improvement += i[key];
                                }
                            }
                            i.improvement = improvement;
                            const construction_keys = ['13', '45', '12']
                            let construction = 0;
                            for (const key of construction_keys) {
                                if (i[key]) {
                                    construction += i[key];
                                }
                            }
                            i.construction = construction;
                            const water_keys = ['14']
                            let water = 0;
                            for (const key of water_keys) {
                                if (i[key]) {
                                    water += i[key];
                                }
                            }
                            i.water = water;
                            const light_keys = ['8']
                            let light = 0;
                            for (const key of light_keys) {
                                if (i[key]) {
                                    light += i[key];
                                }
                            }
                            i.light = light;
                            const social_keys = ['3']
                            let social = 0;
                            for (const key of social_keys) {
                                if (i[key]) {
                                    social += i[key];
                                }
                            }
                            i.social = social;
                            const housing_keys = ['7']
                            let housing = 0;
                            for (const key of housing_keys) {
                                if (i[key]) {
                                    housing += i[key];
                                }
                            }
                            i.housing = housing;
                            const sanitar_keys = ['9']
                            let sanitar = 0;
                            for (const key of sanitar_keys) {
                                if (i[key]) {
                                    sanitar += i[key];
                                }
                            }
                            i.sanitar = sanitar;
                            const burial_keys = ['10']
                            let burial = 0;
                            for (const key of burial_keys) {
                                if (i[key]) {
                                    burial += i[key];
                                }
                            }
                            i.burial = burial;
                            const initiat_keys = ['25']
                            let initiat = 0;
                            for (const key of initiat_keys) {
                                if (i[key]) {
                                    initiat += i[key];
                                }
                            }
                            i.initiat = initiat;
                            const culture_keys = ['6']
                            let culture = 0;
                            for (const key of culture_keys) {
                                if (i[key]) {
                                    culture += i[key];
                                }
                            }
                            i.culture = culture;
                            const sport_keys = ['28']
                            let sport = 0;
                            for (const key of sport_keys) {
                                if (i[key]) {
                                    sport += i[key];
                                }
                            }
                            i.sport = sport;
                            const snp_keys = ['57']
                            let snp = 0;
                            for (const key of snp_keys) {
                                if (i[key]) {
                                    snp += i[key];
                                }
                            }
                            i.snp = snp;
                            const transfert_keys = ['48']
                            let transfert = 0;
                            for (const key of transfert_keys) {
                                if (i[key]) {
                                    transfert += i[key];
                                }
                            }
                            i.transfert = transfert;
                            const other_keys = ['106', '107', '42', '53', '43']
                            let other = 0;
                            for (const key of other_keys) {
                                if (i[key]) {
                                    other += i[key];
                                }
                            }
                            i.other = other;
                            const outcomes_keys = ['func_app', 'improvement', 'construction',
                                'water', 'light', 'housing', 'sanitar',
                                'social', 'burial', 'initiat', 'culture', 'sport', 'snp', 'transfert', 'other'];
                            let outcome = 0;
                            for (const key of outcomes_keys) {
                                if (i[key]) {
                                    outcome += i[key];
                                }
                            }
                            i.outcomes = outcome
                            i.visible = true;
                            i.open = true;
                            for (const key of Object.keys(i)) {
                                if (typeof i[key] === 'number') {
                                    if (total[key]) {
                                        total[key] += i[key];
                                    } else {
                                        total[key] = i[key]
                                    }
                                }
                            }
                            this.unReport.push(i)
                            for (const reg of region_list) {
                                const child_reg = d.childs.filter((a) => a.region === reg.region);
                                if (child_reg.length > 0) {
                                    for (const item of child_reg) {
                                        const attr = item.code;
                                        if (reg[attr]) {
                                            reg[attr] += item.value;
                                        } else {
                                            reg[attr] = item.value;
                                        }
                                    }
                                }
                                let other = 0;
                                for (const key of other_keys) {
                                    if (reg[key]) {
                                        other += reg[key];
                                    }
                                }
                                reg.other = other;
                                let transfert = 0;
                                for (const key of transfert_keys) {
                                    if (reg[key]) {
                                        transfert += reg[key];
                                    }
                                }
                                reg.transfert = transfert;
                                let snp = 0;
                                for (const key of snp_keys) {
                                    if (reg[key]) {
                                        snp += reg[key];
                                    }
                                }
                                reg.snp = snp;
                                let sport = 0;
                                for (const key of sport_keys) {
                                    if (reg[key]) {
                                        sport += reg[key];
                                    }
                                }
                                reg.sport = sport;
                                let culture = 0;
                                for (const key of culture_keys) {
                                    if (reg[key]) {
                                        culture += reg[key];
                                    }
                                }
                                reg.culture = culture;
                                let initiat = 0;
                                for (const key of initiat_keys) {
                                    if (reg[key]) {
                                        initiat += reg[key];
                                    }
                                }
                                reg.initiat = initiat;
                                let burial = 0;
                                for (const key of burial_keys) {
                                    if (reg[key]) {
                                        burial += reg[key];
                                    }
                                }
                                reg.burial = burial;
                                let social = 0;
                                for (const key of social_keys) {
                                    if (reg[key]) {
                                        social += reg[key];
                                    }
                                }
                                reg.social = social;
                                let sanitar = 0;
                                for (const key of sanitar_keys) {
                                    if (reg[key]) {
                                        sanitar += reg[key];
                                    }
                                }
                                reg.sanitar = sanitar;
                                let housing = 0;
                                for (const key of housing_keys) {
                                    if (reg[key]) {
                                        housing += reg[key];
                                    }
                                }
                                reg.housing = housing;
                                let light = 0;
                                for (const key of light_keys) {
                                    if (reg[key]) {
                                        light += reg[key];
                                    }
                                }
                                reg.light = light;
                                let water = 0;
                                for (const key of water_keys) {
                                    if (reg[key]) {
                                        water += reg[key];
                                    }
                                }
                                reg.water = water;
                                let improvement = 0;
                                for (const key of improvement_keys) {
                                    if (reg[key]) {
                                        improvement += reg[key];
                                    }
                                }
                                reg.improvement = improvement;
                                let construction = 0;
                                for (const key of construction_keys) {
                                    if (reg[key]) {
                                        construction += reg[key];
                                    }
                                }
                                reg.construction = construction;
                                let func_app = 0;
                                for (const key of func_app_keys) {
                                    if (reg[key]) {
                                        func_app += reg[key];
                                    }
                                }
                                reg.func_app = func_app;
                                let outcome = 0;
                                for (const key of outcomes_keys) {
                                    if (reg[key]) {
                                        outcome += reg[key];
                                    }
                                }
                                reg.outcomes = outcome;
                                reg.visible = true;
                                this.unReport.push(reg);
                            }
                        }
                        this.unReport.unshift(total);
                    }
                    this.curTab = 0;
                    this.loading = false;
                },
                (error) => {
                    this.makeToast('danger', 'Ошибка запроса', error.toString());
                    this.loading = false;
                }
            )
        },
        openAll() {
            this.open = !this.open;
            for (const r of this.unReport.filter(x => x.region.slice(4, 6) != '00' && x.region.slice(2, 6) != '0101')) {
                r.visible = this.open;
            }
            for (const r of this.unReport.filter(x => x.region.slice(4, 6) == '00')) {
                r.open = this.open;
            }
        },
        openChilds(item) {
            item.open = !item.open;
            for (const r of this.unReport.filter(x => x.region !== item.region && x.region.slice(0, 4) === item.region.slice(0, 4))) {
                r.visible = item.open;
            }
        },
        rowClass(item, type) {
            if (!item || type !== 'row') {
                return;
            }
            if (!item.visible) {
                return 'is-hidden';
            }
        },
    }
}
</script>

<style scoped>
.td-numbering {
    padding: 3px 10px !important;
    text-align: center;
    font-size: 0.7rem !important;
    font-weight: 400 !important;
    color: #6087a0 !important;
    background-color: #f7f9fc;
}
</style>