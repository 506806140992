


























































































































import VueElementLoading from "vue-element-loading"
import VueDraggable from 'vuedraggable'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Report127TableData, Category, Indicator, TableField } from '@/modules/budget/monitoring/reports-constructor/common/types'
import { nameMap } from '@/modules/budget/monitoring/reports-constructor/common/nameMap'
import { formatDigitsGrouping, padLeadingZeros } from '@/modules/budget/monitoring/reports-constructor/common/utils'

interface Section {
    key: string,
    value: any[]
}

@Component({
    components: {
        'draggable': VueDraggable,
        'loading': VueElementLoading
    }
})
export default class Report127 extends Vue {

    @Prop({
        type: Object,
        required: true,
    })
    public tableData!: Report127TableData

    @Prop({
        type: Array,
        required: true,
        default: [],
    })
    private incomes!: Category[]

    @Prop({
        type: Array,
        required: true,
        default: [],
    })
    private expenses!: Category[]

    @Prop({
        type: Array,
        required: true,
        default: [],
    })
    private propIndicators!: Indicator[]

    public tableFields: TableField[] = []
    public categoryFields: TableField[] = []
    public indicators: Indicator[] = []

    public currentPage = 1
    public selectedSection: Section | null = null
    public currentItems: any[] = []
    public sectionOptions: Section[] = []

    public sections = [
        { key: 'generalIncomeTotal', type: 'TOTAL' },
        { key: 'generalIncome', type: 'income' },

        { key: 'generalExpenseTotal', type: 'TOTAL' },
        { key: 'generalExpense', type: 'expense' },

        { key: 'budgetLoanDelta', type: 'TOTAL' },
        { key: 'budgetLoanExpenseTotal', type: 'TOTAL' },
        { key: 'budgetLoanExpense', type: 'expense' },
        { key: 'budgetLoanIncomeTotal', type: 'TOTAL' },
        { key: 'budgetLoanIncome', type: 'income' },

        { key: 'financialActivesDelta', type: 'TOTAL' },
        { key: 'financialActivesExpenseTotal', type: 'TOTAL' },
        { key: 'financialActivesExpense', type: 'expense' },
        { key: 'financialActivesIncomeTotal', type: 'TOTAL' },
        { key: 'financialActivesIncome', type: 'income' },

        { key: 'budgetDeficit', type: 'TOTAL' },
        { key: 'nonOilBudgetDeficit', type: 'TOTAL' },

        { key: 'financeDeficitBudgetDelta', type: 'TOTAL' },
        { key: 'financeDeficitBudgetIncome7Total', type: 'TOTAL' },
        { key: 'financeDeficitBudgetIncome7', type: 'income' },
        { key: 'financeDeficitBudgetExpenseTotal', type: 'TOTAL' },
        { key: 'financeDeficitBudgetExpense', type: 'expense' },
        { key: 'usedBudgetLeft', type: 'TOTAL' },

        { key: 'accSldBeg', type: 'TOTAL' },
        { key: 'accSldEnd', type: 'TOTAL' }
    ]

    private indicatorKeys = ['utv', 'utch', 'plg', 'plgp', 'plgo', 'obz', 'nobz', 'sumrg', 'percentage1', 'percentage2']

    public indicatorsNameMap = {
        utv: 'Утвержденный бюджет на отчетный финансовый год',
        utch: 'Уточненный бюджет на отчетный финансовый год',
        plg: 'Скорректированный бюджет на отчетный финансовый год',
        plgs: 'Сводный план поступлений и финансирования по платежам, сводный план финансирования по обязательствам на отчетный период',
            plgp: 'по платежам',
            plgo: 'по обязательствам',
        obz: 'Принятые обязательства',
        nobz: 'Неоплаченные обязательства',
        sumrg: 'Исполнение поступлениий бюджета и/или оплаченных обязательств по бюджетным программам (подпрограммам)',
        percentage1: 'Исп-е поступ-ий бюджета и/или оплач. обяз-в по бюдж. прогр. (подпрогр.)  к свод. плану  поступ-ий и финанс-ия  на отчет. период, %',
        percentage2: 'Исп-е поступ-ий бюджета и/или оплач. обяз-ва по бюдж. прогр. (подпрогр.) к исполняемому бюджету, %',
    }

    get totalColumnSpan(): number {
        let span = 0
        for (const field of this.tableFields) {        
            if (this.indicatorKeys.includes(field.key)) {
                break
            }
            span++
        }
        return span
    }

    get categoriesSpan(): number {
        const activeIncomes = this.incomes.filter(item => item.active)
        const activeExpenses = this.expenses.filter(item => item.active)
        return activeExpenses.length > activeIncomes.length ? activeExpenses.length : activeIncomes.length
    }

    private created() {
        this.indicators = JSON.parse(JSON.stringify(this.propIndicators))
        this.constructTableFields(true)
        this.fillSections()
    }

    private fillSections() {
        let tempSectionOptions: Section[] = []

        let incomesSection: any = []
        if (this.tableData.incomeTotal) {
            incomesSection = incomesSection.concat([{...this.tableData.incomeTotal, type: 'TOTAL'}])
        }
        if (this.tableData.generalIncomeTotal) {
            this.tableData.generalIncome!.forEach(it => it.type = 'income')
            incomesSection = incomesSection
                .concat(
                    [{...this.tableData.generalIncomeTotal, type: 'TOTAL'}]
                    // @ts-ignore
                    .concat(this.tableData.generalIncome)
                ) 
        }
        if (incomesSection.length > 0) {
            tempSectionOptions.push({ key: 'I. ДОХОДЫ', value: incomesSection })
        }

        let expensesSection: any = []
        if (this.tableData.expenseTotal) {
            expensesSection = expensesSection.concat([{...this.tableData.expenseTotal, type: 'TOTAL'}])
        }
        if (this.tableData.generalExpenseTotal) {
            this.tableData.generalExpense!.forEach(it => it.type = 'expense')
            // @ts-ignore
            expensesSection = expensesSection
                .concat(
                    [{...this.tableData.generalExpenseTotal, type: 'TOTAL'}]
                    // @ts-ignore
                    .concat(this.tableData.generalExpense)
                ) 
        } 
        if (expensesSection.length > 0) {
            tempSectionOptions.push({ key: 'II. ЗАТРАТЫ', value: expensesSection })
        }

        if (!this.tableData.budgetLoanExpense) {
            this.sectionOptions = tempSectionOptions
            this.selectedSection = this.sectionOptions[0]
            this.handlePageChange(1)
            return
        }

        // @ts-ignore
        this.tableData.budgetLoanExpense.forEach(it => it.type = 'expense')
        // @ts-ignore
        this.tableData.budgetLoanIncome.forEach(it => it.type = 'income')

        const budgetLoanSection = [{...this.tableData.budgetLoanDelta, type: 'TOTAL'}]
            .concat([{...this.tableData.budgetLoanExpenseTotal, type: 'TOTAL'}])
            // @ts-ignore
            .concat(this.tableData.budgetLoanExpense)
            // @ts-ignore
            .concat([{...this.tableData.budgetLoanIncomeTotal, type: 'TOTAL'}])
            // @ts-ignore
            .concat(this.tableData.budgetLoanIncome)

        // @ts-ignore
        this.tableData.financialActivesExpense.forEach(it => it.type = 'expense')
        // @ts-ignore
        this.tableData.financialActivesIncome.forEach(it => it.type = 'income')

        const financialActivesSection = [{...this.tableData.financialActivesDelta, type: 'TOTAL'}]
            .concat([{...this.tableData.financialActivesExpenseTotal, type: 'TOTAL'}])
            // @ts-ignore
            .concat(this.tableData.financialActivesExpense)
            // @ts-ignore
            .concat([{...this.tableData.financialActivesIncomeTotal, type: 'TOTAL'}])
            // @ts-ignore
            .concat(this.tableData.financialActivesIncome)

        // @ts-ignore
        this.tableData.financeDeficitBudgetExpense.forEach(it => it.type = 'expense')
        // @ts-ignore
        this.tableData.financeDeficitBudgetIncome7.forEach(it => it.type = 'income')
        // @ts-ignore
        const budgetDeficitSection = [{...this.tableData.budgetDeficit, type: 'TOTAL'}]
            // @ts-ignore
            .concat([{...this.tableData.nonOilBudgetDeficit, type: 'TOTAL'}])
            // @ts-ignore
            .concat([{...this.tableData.financeDeficitBudgetDelta, type: 'TOTAL'}])
            // @ts-ignore
            .concat([{...this.tableData.financeDeficitBudgetIncome7Total, type: 'TOTAL'}])
            // @ts-ignore
            .concat(this.tableData.financeDeficitBudgetIncome7)
            // @ts-ignore
            .concat([{...this.tableData.financeDeficitBudgetExpenseTotal, type: 'TOTAL'}])
            // @ts-ignore
            .concat(this.tableData.financeDeficitBudgetExpense)
            // @ts-ignore
            .concat([{...this.tableData.usedBudgetLeft, type: 'TOTAL'}])
            // @ts-ignore
            .concat([{nameRu: 'Справочно: Остатки бюджетных средств', type: 'TOTAL', index: -1, nobz: '', obz: '', percentage1: '', percentage2: '', plg: '', plgo: '', plgp: '', sumrg: '', utch: '', utv: ''}])
            // @ts-ignore
            .concat([{...this.tableData.accSldBeg, type: 'TOTAL'}])
            // @ts-ignore
            .concat([{...this.tableData.accSldEnd, type: 'TOTAL'}])

        
        if (this.tableData.budgetLoanExpense) {
            tempSectionOptions = tempSectionOptions.concat([
                {
                    key: 'III. ЧИСТОЕ БЮДЖЕТНОЕ КРЕДИТОВАНИЕ',
                    value: budgetLoanSection
                },
                {
                    key: 'IV. САЛЬДО ПО ОПЕРАЦИЯМ С ФИНАНСОВЫМИ АКТИВАМИ',
                    value: financialActivesSection
                },
                {
                    key: 'V. ДЕФИЦИТ (ПРОФИЦИТ) БЮДЖЕТА \n VI. НЕНЕФТЯНОЙ ДЕФИЦИТ (ПРОФИЦИТ) БЮДЖЕТА \n VII ФИНАНСИРОВАНИЕ ДЕФИЦИТА (ИСПОЛЬЗОВАНИЕ ПРОФИЦИТА) БЮДЖЕТА \n Справочно: Остатки бюджетных средств',
                    value: budgetDeficitSection
                }
            ])
        }

        this.sectionOptions = tempSectionOptions

        this.selectedSection = this.sectionOptions[0]
        this.handlePageChange(1)
    }

    private constructTableFields(isOnCreate: boolean) {
        const tempTableFields: TableField[] = []
        tempTableFields.push({ key: 'codes', label: 'Коды бюджетной классификации', active: true })

        let tempIncomes = this.incomes
        let tempExpenses = this.expenses

        if (tempIncomes.length == 0) {
            tempIncomes = [{ key: 'temp_income', selected: [], active: true }]
        }
        if (tempExpenses.length == 0) {
            tempExpenses = [{ key: 'temp_expenses', selected: [], active: true }]
        }

        const activeIncomes = tempIncomes.filter(item => item.active)
        const activeExpenses = tempExpenses.filter(item => item.active)
        
        const categoriesSpan = this.categoriesSpan === 0 ? 1 : this.categoriesSpan
        
        const tempCategoryFields: TableField[] = []
        for (let i = 0; i < categoriesSpan; i++) {
            const incomeKey = activeIncomes[i]?.key
            const expenseKey = activeExpenses[i]?.key
            // @ts-ignore
            const incomeLabel = nameMap[incomeKey]
            // @ts-ignore
            const expenseLabel = nameMap[expenseKey]

            const field: TableField = {
                key: `${incomeKey}/${expenseKey}`,
                label: `${incomeLabel??' - '}/${expenseLabel??' - '}`,
                income: incomeKey,
                expense: expenseKey,
                active: true
            }
            tempCategoryFields.push(field)
        }
        this.categoryFields = tempCategoryFields

        tempTableFields.push({ key: 'nameRu', label: 'Наименование', active: true })

        const indicators = isOnCreate ? this.propIndicators : this.indicators

        indicators.filter(it => it.active).forEach(indicator => {
            // @ts-ignore
            tempTableFields.push({ key: indicator.key, label: this.indicatorsNameMap[indicator.key], active: true })
        })

        this.tableFields = tempTableFields
    }

    public handlePageChange(page: number) {
        const indexedPage = page - 1
        this.currentPage = page
        const startIndex = indexedPage * 100
        const endIndex = startIndex + 100
        this.currentItems = this.selectedSection!.value.slice(startIndex, endIndex)
    }

    public onSectionChange() {
        this.handlePageChange(1)
    }

    public draggableHeader(columnKey: string) {
        return this.indicatorKeys.includes(columnKey)
    }

    public isTotalColumn(columnKey: string) {
        return ['plan', 'fact', 'delta1'].includes(columnKey)
    }

    public synchronizeIndicatorsOrder() {
        const fullIndicatorKeys = this.indicatorKeys.filter(it => ['plgp', 'plgo'].notIncludes(it)).concat(['plgs'])
        const indicatorsOrder = this.tableFields
            .filter(it => fullIndicatorKeys.includes(it.key))
            .map(it => it.key)

            fullIndicatorKeys.forEach(indicatorKey => {
            if (indicatorsOrder.notIncludes(indicatorKey)) {
                indicatorsOrder.push(indicatorKey)
            }
        })

        const tempIndicators: Indicator[] = []
        indicatorsOrder.forEach(indicatorKey => {
            const IndicatorObject = this.indicators.find(it => it.key === indicatorKey)!
            tempIndicators.push(IndicatorObject)
        })

        this.indicators = tempIndicators
        this.$emit('update-indicators', tempIndicators)
    }

    public synchronizeVisiblityInTable() {
        this.$emit('update-indicators', JSON.parse(JSON.stringify(this.indicators)))
        this.constructTableFields(false)
    }

    public leftPaddingBasedOnCode(value: number | string, code: string, type: 'income' | 'expense'): string {
        let padSize = 1
        if (type === 'income') {
            if (['cls', 'spf'].includes(code)) {
                padSize = 2
            }
            return this.padLeadingZeros(value, padSize)
        } else if (type === 'expense') {
            if (['gr', 'pgr'].includes(code)) {
                padSize = 2
            } else if (['abp', 'prg', 'ppr', 'spf'].includes(code)) {
                padSize = 3
            }
            return this.padLeadingZeros(value, padSize)
        } else {
            throw new Error("Unsupported type")
        }
    }

    public formatDigitsGrouping = formatDigitsGrouping
    public padLeadingZeros = padLeadingZeros
}
